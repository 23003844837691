.showDownloadsModal {
  .content-top {
    display: flex;
    justify-content: space-between;
  }

  .title {
    font-size: 18px;
    font-weight: bold;
  }

  .fa-times {
    cursor: pointer;

    &:hover {
      opacity: 0.6;
    }
  }

  .item {
    align-items: center;
    background-color: #282828;
    border-radius: 4px;

    height: 48px;
    display: flex;
    line-height: 48px;
    margin-bottom: 8px;
    text-decoration: none;
    width: 100%;

    .icon {
      align-items: center;
      color: var(--primary-color);
      display: flex;
      font-size: 22px;
      font-weight: 600;
      height: 24px;
      justify-content: center;
      margin-left: 16px;
      margin-right: 16px;
      width: 24px;
    }

    .name {
      color: #d9d9db;
      font-size: 14px;
      font-weight: 500;
    }
  }
}
