.moveFolder-folder-item {
  align-items: center;
  border-top: 1px solid #1e1e1e;
  cursor: pointer;
  height: 56px;
  display: flex;
  justify-content: space-between;
  padding: 0px 24px;
  text-decoration: none;
  width: 100%;

  &:hover {
    background-color: #0d0d0d;
    color: #fff;
    transition: 0.2s linear;
  }

  .folder-info {
    align-items: center;
    color: #b3b3b3;

    display: flex;
  }
  .radio-button-icon {
    align-items: center;

    display: flex;
    font-size: 24px;
    height: 24px;
    margin-right: 8px;
    width: 24px;
  }

  .radio-button-icon-active {
    color: #fff;
  }

  .name {
    color: #fff;
    font-size: 14px;
    font-weight: 500;
  }

  .open-icon {
    align-items: center;
    color: #b3b3b3;
    cursor: pointer;
    display: flex;
    font-size: 20px;

    &:hover {
      color: #fff;
      transition: 0.2s linear;
      transform: scale(1.06);
    }
  }
}

.moveFolder-folder-item-disabled {
  align-items: center;
  border-top: 1px solid #1e1e1e;
  color: rgba(255, 255, 255, 0.4);
  height: 56px;
  display: flex;
  justify-content: space-between;
  padding: 0px 24px;
  text-decoration: none;
  width: 100%;
}
