.submission-page {
  .page-title {
    font-size: 80px;
    font-weight: 600;
  }

  .page-top {
    display: flex;
    justify-content: space-between;
    padding-bottom: 32px;

    .folder-buttons {
      display: flex;
      margin-top: 16px;
      position: relative;
    }
  }

  .folder-info {
    display: flex;

    .folder-details {
      flex: 1;
    }
  }

  .submission-text {
    align-items: center;

    display: flex;
    font-size: 12px;
    font-weight: 600;
    height: 24px;
    text-transform: uppercase;
  }

  .folder-image {
    background-color: #282828;
    background-position: center;
    background-size: cover;
    border-radius: 2px;
    box-shadow: 0 4px 60px rgba(0, 0, 0, 0.7);
    height: 214px;
    margin-right: 32px;
    width: 214px;
  }

  .added-box {
    align-items: center;
    display: flex;

    .checkmark-icon {
      color: #16bf63;
      display: flex;
      font-size: 24px;
      margin-right: 8px;
    }

    .added-text {
      color: #b3b3b3;
      font-weight: 500;
    }
  }

  .removed-message {
    color: #b3b3b3;
    font-size: 14px;
    font-weight: 500;
    padding-top: 80px;
    text-align: center;
    width: 100%;
  }

  .folders-container {
    display: inline-block;

    width: 100%;
  }

  .page-label {
    align-items: center;
    color: #fff;
    display: flex;
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 16px;
  }
}

@media only screen and (max-width: 660px) {
  .submission-page {
    height: 100vh;
    margin-left: 0px;
    width: 100%;

    .page-top {
      padding-top: 96px;
      padding-bottom: 32px;
    }

    .filter-container {
      display: none;
    }

    .share-button-mobile {
      display: none;
      width: 100%;
    }

    .folder-info-container {
      width: 100%;
      .folder-info {
        align-items: center;
        display: flex;
        flex-direction: column;
        width: 100%;

        .folder-details {
          align-items: center;
          display: flex;
          flex-direction: column;
          padding-right: 0px;

          .page-title {
            font-size: 24px;
            font-weight: 600;
          }

          .folder-buttons-mobile {
            display: flex;
          }
        }
      }

      .folder-image {
        height: 180px;
        margin-bottom: 24px;
        margin-right: 0px;
        width: 180px;
      }
    }

    .share-button-mobile {
      display: flex;
      margin-top: 16px;
      width: 120px;
    }

    .menu-button {
      display: none;
    }
  }

  .share-button {
    display: none;
  }
}
