.inviteSubmissionModal {
  .content-top {
    display: flex;
    justify-content: space-between;
  }

  .title {
    font-size: 18px;
    font-weight: bold;
  }

  .submission-text {
    font-size: 16px;
    margin-bottom: 24px;
  }
}
