.folder-tile-large {
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  float: left;
  flex-direction: column;
  height: 220px;
  margin-bottom: 24px;
  margin-right: 40px;
  width: 140px;

  .folder-info {
    display: flex;
    flex-direction: column;
    width: 100%;

    .folder-icon {
      color: #fff;
      display: flex;
      font-size: 26px;
      margin-right: 16px;
    }

    .folder-name {
      color: #fff;
      font-size: 15px;
      font-weight: 500;
      margin-bottom: 4px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .folder-text {
      color: #b3b3b3;
      font-size: 14px;

      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .folder-label {
    color: #fff;
    font-size: 14px;
    font-weight: 500;
  }

  .folder-tile-image {
    align-items: center;
    background: #2a2a2a;
    background-position: center;
    background-size: cover;
    border-radius: 2px;
    display: flex;
    color: #181818;
    font-size: 56px;
    justify-content: center;
    width: 140px;
    height: 140px;
  }

  .folder-tile-icon {
    display: flex;
  }

  .bottom {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
    padding: 16px 0px;
    position: relative;

    .folder-info-container {
      align-items: center;
      display: flex;
      justify-content: space-between;

      .open-arrow {
        color: #b3b3b3;
        font-size: 24px;
      }
    }

    .profile-image {
      background-color: #36393f;
      background-position: center;
      background-size: cover;
      border-radius: 8px;
      height: 46px;
      margin-top: -32px;
      width: 46px;
    }

    .folder-user {
      color: #b3b3b3;
      margin-top: 4px;
      font-weight: 500;

      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .bottom-row {
      align-items: center;
      display: flex;
      justify-content: space-between;
      width: 100%;

      .contents {
        color: #b3b3b3;
        font-size: 12px;
        font-weight: 500;
      }

      .in-cart-button {
        align-items: center;
        background-color: #414448;
        border-radius: 4px;
        color: #fff;
        display: flex;
        font-size: 14px;
        font-weight: 500;
        height: 32px;
        justify-content: center;
        padding: 8px;
        width: 74px;
      }

      .purchase-button {
        align-items: center;
        background-color: var(--primary-color);
        border-radius: 4px;
        color: #fff;
        display: flex;
        height: 32px;
        padding: 8px;

        .purchase-icon {
          display: flex;
          font-size: 18px;
        }

        .purchase-price {
          background-color: #516cc1;
          border-radius: 4px;
          display: flex;
          font-size: 12px;
          font-weight: 500;
          justify-content: center;
          margin-left: 12px;
          min-width: 28px;
          padding: 4px;
        }
      }
    }
  }
}

@media only screen and (max-width: 660px) {
  .folder-tile-large {
    margin-bottom: 24px;
    margin-right: 16px;
    width: calc(50% - 32px);

    .folder-tile-image {
      margin-bottom: 8px;
      padding-top: 100%;
      position: relative;
      width: 100%;

      .folder-tile-icon {
        display: flex;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
}
