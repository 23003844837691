.react-contexify {
  background-color: #181818;

  border-radius: 4px;
  z-index: 1000;
}

.react-contexify__item__content {
  color: #b3b3b3;
  height: 36px;
}

.react-contexify__item:not(.react-contexify__item--disabled):hover
  > .react-contexify__item__content,
.react-contexify__item:not(.react-contexify__item--disabled):focus
  > .react-contexify__item__content {
  background-color: #2a2a2a;
}

.react-contexify__separator {
  background: #2a2a2a;
}
