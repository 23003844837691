.filter-container {
  align-items: center;
  display: none;
  justify-content: space-between;
  margin-bottom: 24px;
  position: relative;
  width: 100%;

  .track-count {
    color: #b3b3b3;
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
  }

  .filters {
    display: flex;
    .filter-button {
      align-items: center;
      background-color: #282828;
      border-radius: 4px;
      cursor: pointer;
      display: flex;
      font-weight: 500;
      height: 32px;
      justify-content: center;
      margin-right: 8px;
      padding: 0px 16px;

      &:hover {
        background-color: #282828;
        color: #fff;
      }
    }
    .filter-button-active {
      color: #b3b3b3;
    }
  }
}

.filter-menu {
  background: #000;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.05);
  border: 1px solid #282828;
  border-radius: 4px;
  position: absolute;
  margin-top: 8px;
  padding-top: 8px;
  width: 200px;
  z-index: 100;

  .empty-filter-container {
    align-items: center;
    display: flex;
    height: 80px;
    justify-content: center;
    width: 100%;
  }

  .filter-options-container {
    max-height: 200px;
    padding-left: 8px;
    overflow-y: scroll;
  }

  .filter-option {
    align-items: center;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    height: 40px;
    line-height: 22px;
    padding: 0px 8px;
    width: 100%;

    &:hover {
      background-color: #000;
    }

    .icon {
      align-items: center;
      color: #b3b3b3;
      display: flex;
      font-size: 22px;
      margin-right: 8px;

      &:hover {
        color: #fff;
      }
    }
  }

  .filter-bottom {
    align-items: center;
    border-top: 1px solid #282828;
    display: flex;
    height: 64px;
    justify-content: space-between;
    margin-top: 8px;
    padding: 0px 16px;
    width: 100%;

    .clear-button {
      cursor: pointer;
      font-weight: 500;
      text-decoration: underline;

      &:hover {
        color: #fff;
      }
    }

    .save-button {
      align-items: center;
      background: var(--primary-color);
      border: none;
      border-radius: 4px;
      color: white;
      cursor: pointer;
      display: flex;
      font-size: 14px;
      font-weight: 500;
      height: 32px;
      padding: 0px 24px;
      justify-content: center;
      text-decoration: none;
      -webkit-appearance: none;

      &:hover {
        opacity: 0.8;
      }
    }
  }
}

.filter-container-hidden {
  display: none;
}

@media only screen and (max-width: 660px) {
  .filter-container {
    .filter-button {
      font-size: 12px;
    }
  }
  .filter-menu {
    left: 0;
    width: 100%;
  }
}
