.seekBar {
  align-items: center;
  display: flex;

  .time-left {
    color: #b3b3b3;
    font-size: 12px;
    font-weight: 500;
    text-align: left;
    width: 40px;
  }

  .time-right {
    color: #b3b3b3;
    font-size: 12px;
    font-weight: 500;
    margin-left: 8px;
    text-align: right;
    width: 32px;
  }

  .slider-container {
    --progress-bar-height: 4px;
  }

  .slider-container {
    position: relative;
    width: 100%;
  }

  /* Background */
  .slider-container::before {
    content: "";
    background-color: rgb(81, 81, 81);
    width: 100%;
    height: var(--progress-bar-height);
    display: block;
    position: absolute;
    border-radius: 50px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    pointer-events: none;
    overflow: hidden;
  }

  /* Custom Progress Bar */
  .progress-bar-cover {
    background-color: #fff;
    width: 0%;
    height: var(--progress-bar-height);
    display: block;
    position: absolute;
    border-radius: 50px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    user-select: none;
    pointer-events: none;
  }

  /*  Hide Original */
  .range {
    -webkit-appearance: none;
    background-color: white;
    width: 100%;
    cursor: pointer;
    opacity: 0;
    margin: 0 auto;
  }

  .slider-container {
    --thumb-width: 12px;
    --thumb-height: 12px;
  }

  .range::-webkit-slider-thumb {
    width: var(--thumb-width);
    height: var(--thumb-height);

    background: #350f2d;
    border: 1px solid #000000;
    border-radius: 50%;
    cursor: pointer;
    -webkit-appearance: none;
  }

  .thumb {
    width: var(--thumb-width);
    height: var(--thumb-height);
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.753);
    z-index: 3;
    background: rgb(255, 255, 255);
    position: absolute;
    border-radius: 50%;
    top: 50%;
    transform: translate(0%, -50%);
    pointer-events: none; /* Remove pointer events on thumb so user can click on the actual thumb beaneath it!  */
    user-select: none; /*  Prevent Accidentally highlighting the number while sliding the cursor  */
  }

  .thumb-hidden {
    width: var(--thumb-width);
    height: var(--thumb-height);
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.753);
    z-index: 3;
    background: rgb(255, 255, 255);
    position: absolute;
    border-radius: 50%;
    top: 50%;
    transform: translate(0%, -50%);
    pointer-events: none; /* Remove pointer events on thumb so user can click on the actual thumb beaneath it!  */
    user-select: none; /*  Prevent Accidentally highlighting the number while sliding the cursor  */
    display: none;
  }
}
