.settingsModal {
  background-color: #000;

  .content-top {
    display: flex;
    justify-content: space-between;
  }

  .title {
    font-size: 18px;
    font-weight: bold;
  }

  .fa-times {
    cursor: pointer;

    &:hover {
      opacity: 0.6;
    }
  }
}
