.shareFolderModal {
  background-color: #000;
  border-radius: 4px;

  .content-top {
    display: flex;
    justify-content: space-between;
    padding: 24px 24px 0px 24px;
  }

  .content-bottom {
    border-top: 1px solid rgb(32, 34, 37);
    padding: 24px 24px 16px 24px;
  }

  .title {
    font-size: 18px;
    font-weight: bold;
  }

  .member-list {
    flex: 1;
    margin-top: 24px;
    max-height: 240px;
    overflow-y: auto;
    padding: 0px 24px;

    .member-item {
      align-items: center;
      display: flex;
      justify-content: space-between;
      margin-bottom: 24px;
    }

    .member-info {
      align-items: center;
      display: flex;
      line-height: 36px;
    }

    .member-icon {
      align-items: center;
      background-color: #2a2a2a;
      background-position: center;
      background-size: cover;
      border-radius: 100%;
      display: flex;
      font-weight: 500;
      height: 36px;
      justify-content: center;
      line-height: 36px;
      width: 36px;
    }

    .member-icon-inactive {
      align-items: center;
      border: 1px dotted #36393f;
      border-radius: 100%;
      color: #b3b3b3;
      display: flex;
      font-weight: 500;
      height: 36px;
      justify-content: center;
      width: 36px;
    }

    .member-name-inactive {
      color: #fff;
      font-weight: 500;
      margin-left: 16px;
    }

    .member-name {
      font-weight: 500;
      margin-left: 16px;
    }

    .member-permissions {
      line-height: 36px;
      position: relative;

      .permission-container {
        align-items: center;
        cursor: pointer;
        display: flex;

        &:hover {
          color: #fff;
        }
      }

      .member-permission-text {
        font-weight: 500;
        margin-right: 4px;
      }

      .chevron-down-outline {
      }
    }
  }

  .divider {
    background-color: #2a2a2a;
    height: 1px;
    margin: 24px 0px;
    width: 100%;
  }

  .share-form {
    margin-bottom: 24px;

    label {
      color: #b3b3b3;
      font-size: 12px;
      font-weight: 600;
      text-transform: uppercase;
    }

    .form-box {
      align-items: center;
      display: flex;
      margin-top: 8px;

      .email-form {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        background-color: #2a2a2a;
        border-radius: 4px;
        padding-left: 4px;
      }
    }

    .email-form-container {
      padding: 0px 24px;
    }

    .just-link {
      padding-left: 20px;
      padding-right: 20px;
    }

    .permissions-field {
      border-radius: 0px 4px 4px 0px;
      cursor: pointer;

      display: flex;
      margin-left: -4px;
      padding-right: 16px;
      position: relative;

      &:hover {
        color: #fff;
      }

      .permissions-container {
        align-items: center;
        display: flex;
      }

      .permissions-text {
        margin-right: 4px;
      }
    }

    .link-access-text {
      margin-left: 16px;
    }

    .sharing-link-field {
      background-color: #000;
      border: 1px solid #36393f;
      color: #b3b3b3;
    }

    .text-field {
      flex: 1;
    }

    .invite-button {
      margin-left: 8px;
      width: 120px;
    }
  }
}

.react-multi-email {
  margin: 0;
  max-width: calc(100% - 160px);
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  outline: 0;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  text-align: left;
  padding: 0 0.4em;
  border-radius: 0.28571429rem;
  background-color: #2a2a2a;
  -webkit-transition: box-shadow 0.1s ease, border-color 0.1s ease;
  transition: box-shadow 0.1s ease, border-color 0.1s ease;
  font-size: 16px;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  align-content: flex-start;
  color: #fff;
}
.react-multi-email > span[data-placeholder] {
  color: #b3b3b3;
  display: none;
  position: absolute;
}

.react-multi-email.empty > span[data-placeholder] {
  display: inline;
  padding-left: 4px;
}
.react-multi-email.empty {
  cursor: text;
}
.react-multi-email.focused > span[data-placeholder] {
  display: none;
}

.react-multi-email > input {
  outline: none !important;
  border: 0 none !important;
  display: inline-block !important;
  background-color: #2a2a2a;
  vertical-align: baseline !important;
  font-size: 16px;
  line-height: 48px;
  height: 40px;
  color: #fff;
  min-width: 100px;
}

.react-multi-email [data-tag] {
  line-height: 1;
  vertical-align: baseline;
  margin: 0.14285714em;
  background-image: none;
  padding: 0.5833em 0.833em;
  text-transform: none;
  font-weight: 500;
  -webkit-transition: background 0.1s ease;
  -o-transition: background 0.1s ease;
  transition: background 0.1s ease;
  font-size: 0.8rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  max-width: 100%;
  background-color: #36393f;
  border-radius: 4px;
  margin: 5px;
}
.react-multi-email [data-tag] [data-tag-item] {
  max-width: 100%;
  overflow: hidden;
}
.react-multi-email [data-tag]:first-child {
  margin-left: 0;
}
.react-multi-email [data-tag] [data-tag-handle] {
  margin-left: 0.833em;
  cursor: pointer;
}
