.analytics-track {
  align-items: center;
  border-radius: 4px;
  display: grid;
  grid-template-columns: 0.3fr 5fr 2fr 2fr 2.2fr;
  justify-content: space-between;
  min-height: 56px;
  padding: 0px 56px 0px 24px;
  position: relative;
  width: 100%;
  transition: 500ms;

  &:hover {
    background-color: rgba(255, 255, 255, 0.05);
    cursor: pointer;
    transition: 0.2s linear;
  }

  .parent-labels {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: baseline;
    right: 120px;
  }

  .parent-label {
    margin-left: 16px;
    margin-right: 16px;
    font-size: 11px;
    font-weight: 500;
    opacity: 0.7;
  }

  .track-number {
    color: rgb(179, 179, 179);
    font-size: 16px;
    font-weight: 500;
    margin-right: 16px;
    width: 16px;
  }

  .playing-track {
    align-items: center;
    display: flex;
    font-size: 20px;
    margin-right: 8px;
    text-align: center;
    width: 24px;
  }

  .play-button {
    align-items: center;
    background-color: #fff;
    color: #000;
    border-radius: 100%;
    display: flex;
    font-size: 14px;
    height: 26px;
    justify-content: center;
    margin-right: 14px;
    margin-left: -8px;
    width: 26px;

    &:hover {
      transition: 0.2s linear;
      transform: scale(1.06);
    }

    .play-icon {
      display: flex;
      margin-left: 1px;
    }

    .pause-icon {
      display: flex;
    }
  }

  .disabled {
    opacity: 0.5;
  }

  .info {
    flex: 1;
    margin-right: 24px;
    overflow: hidden;

    white-space: nowrap;

    .track-name {
      font-size: 15px;
      font-weight: 500;
      display: inline-flex;
      margin-bottom: 2px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 100%;

      &:hover {
        text-decoration: underline;
      }
    }

    .track-name-active {
      color: var(--primary-color);
    }

    .track-page-track-name {
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 4px;
    }

    .track-page-track-name-active {
      color: var(--primary-color);
    }

    .track-tags {
      color: #b3b3b3;
      font-size: 14px;
      font-weight: 500;

      .username {
        &:hover {
          color: #fff;
          text-decoration: underline;
        }
      }
    }
  }

  .play-count {
    color: #b3b3b3;
    font-weight: 500;
  }

  .date-added {
    color: #b3b3b3;
    font-weight: 500;
  }

  .right {
    display: flex;
    justify-content: flex-end;
    position: relative;

    .like-button {
      align-items: center;
      color: #b3b3b3;
      display: flex;
      font-size: 20px;
      margin-right: 32px;

      &:hover {
        color: var(--primary-color);
        transition: 0.2s linear;
        transform: scale(1.06);
      }
    }

    .like-button-active {
      color: var(--primary-color);
    }

    .download-button {
      align-items: center;
      color: #b3b3b3;
      display: flex;
      font-size: 24px;
      margin-left: 32px;
      width: 24px;

      &:hover {
        color: #fff;
        transition: 0.2s linear;
        transform: scale(1.06);
      }
    }

    .menu-button {
      align-items: center;
      color: #b3b3b3;
      display: flex;
      font-size: 20px;
      margin-left: 32px;
      width: 24px;

      &:hover {
        color: #fff;
        transition: 0.2s linear;
        transform: scale(1.06);
      }
    }

    .comment-button {
      align-items: center;
      color: #b3b3b3;
      display: flex;
      font-size: 20px;

      &:hover {
        color: #fff;
        transition: 0.2s linear;
        transform: scale(1.06);
      }
    }

    .share-button {
      color: #b3b3b3;
      display: flex;
      font-size: 20px;
      margin-right: 40px;

      &:hover {
        color: #fff;
        transition: 0.2s linear;
        transform: scale(1.06);
      }
    }

    .trackMenu-container {
      position: absolute;
      z-index: 100;
      right: -16px;
      top: 32px;
    }
  }
}

@media only screen and (max-width: 1000px) {
  .analytics-track {
    padding: 8px 16px;
    grid-template-columns: 0.3fr 7fr 2fr;

    &:hover {
      background-color: #121212;
    }

    .info .track-name {
      display: block;
    }

    .left {
      width: calc(100% - 48px);

      .play-button {
        margin-right: 12px;

        &:hover {
          background-color: var(--primary-color);
          color: #fff;
        }
      }

      .info {
        flex: 1;
        margin-right: 0px;

        .track-name {
          font-size: 15px;
        }

        .track-tags {
          font-size: 13px;
        }
      }
    }

    .date-added {
      display: none;
    }

    .right {
      .like-button {
        display: none;
      }

      .like-button-active {
        display: block;
      }

      .comment-button {
        display: none;
      }

      .download-button {
        display: none;
      }
      .menu-button {
        display: none;
      }
    }
  }
}
