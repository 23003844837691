.landing-page-container {
  --width: 630px;
  --spacing: 30px;
  --outsideSpacing: 30px;
  --borderRadius: 8px;
  --secondaryColor: #82858f;

  background-color: #050406;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: scroll;

  .page-header {
    width: 100vw;
    display: flex;
    justify-content: center;
    padding: 80px 32px 32px 32px;
  }

  .header-banner {
    align-items: center;
    border-radius: var(--borderRadius);
    display: flex;
    flex-direction: column;
    height: calc(100vh - 300px);
    justify-content: center;
    text-align: center;
    width: calc(100vw - 64px);

    .sign-up-button {
      height: 48px;
      width: 160px;
      box-shadow: 0px 2px 16px #000;
    }
  }

  .header-logo-badge {
    background: linear-gradient(#f17a9f 0, #e0245e 71%);
    border-radius: var(--borderRadius);
    display: flex;
    font-family: "New-York-To-Las-Vegas";
    font-size: 88px;
    height: 80px;
    line-height: 80px;
    justify-content: center;
    margin-bottom: 24px;
    width: 80px;
  }

  .header-logo-letter {
    margin-top: -8px;
  }

  .header-logo {
    font-family: "New-York-To-Las-Vegas";
  }

  .header-description {
    line-height: 39px;
    letter-spacing: -1px;
    background: linear-gradient(
      270deg,
      #e7f8ee,
      #b16cff 32%,
      #e8648d 71%,
      #ff95b7
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    max-width: 500px;
    padding: 20px 0;
    margin: 0 auto;
    max-width: 900px;
    font-size: 80px;
    font-weight: 700;
    line-height: 1.083em;
  }

  .header-body {
    color: var(--secondaryColor);
    font-size: 26px;
    font-weight: 600;

    margin: 0 auto 40px;
    max-width: 700px;
  }

  .screenshot-section {
    margin: 0 auto var(--spacing);
    display: flex;
    align-items: stretch;
    padding: 0 var(--outsideSpacing);
  }

  .mobile-screenshot-section {
    flex-basis: calc(40% - var(--spacing) / 2);
    position: relative;
    border-radius: var(--borderRadius);

    z-index: 1;
    overflow: hidden;

    .mobile-screenshot-background {
      height: 100%;
      width: 100%;
      background: 625% 500%/90% 90%
          radial-gradient(
            closest-side,
            rgba(228, 79, 79, 0.7),
            rgba(228, 79, 79, 0)
          )
          no-repeat,
        600% 180%/90% 90%
          radial-gradient(
            closest-side,
            rgba(228, 79, 79, 0.7),
            rgba(228, 79, 79, 0)
          )
          no-repeat,
        100% 150%/75% 50%
          radial-gradient(
            closest-side,
            rgba(250, 250, 218, 0.2),
            rgba(250, 250, 218, 0)
          )
          no-repeat,
        -400% -100%/90% 80%
          radial-gradient(
            closest-side,
            rgba(250, 250, 218, 0.3),
            rgba(250, 250, 218, 0)
          )
          no-repeat,
        -100% -250%/85% 80%
          radial-gradient(
            closest-side,
            rgba(94, 225, 249, 0.8),
            rgba(94, 225, 249, 0)
          )
          no-repeat,
        -170% 100%/70% 60% radial-gradient(
            closest-side,
            rgba(94, 225, 249, 0.6),
            rgba(94, 225, 249, 0)
          ) no-repeat,
        50% 50%/100% 100%
          linear-gradient(
            30deg,
            #6e10ce 10%,
            rgba(110, 16, 206, 0) 70%,
            hsla(0, 0%, 100%, 0) 90%
          )
          no-repeat,
        linear-gradient(
            144deg,
            rgba(233, 235, 104, 0),
            rgba(233, 235, 104, 0.3)
          )
          no-repeat,
        linear-gradient(
            90deg,
            rgba(104, 184, 235, 0.11),
            rgba(15, 216, 223, 0.11)
          )
          no-repeat,
        #fff;

      -webkit-animation: filter-animation 4s ease-in-out infinite;
      animation: filter-animation 4s ease-in-out infinite;
      -webkit-animation-direction: alternate;
      animation-direction: alternate;
      position: absolute;
    }

    .mobile-screenshot-content {
      display: flex;
      flex-direction: column;
      height: 100%;
      width: 100%;
      padding: 3vw 5vw 5vw;
      z-index: 10;
    }

    .phone-screenshot {
      height: auto;
      max-width: 230px;
      width: 100%;
      position: relative;
      z-index: 2;
      margin: 0 auto 50px;
    }

    .text-container {
      font-size: 24px;
      line-height: 1.28em;
      padding: 0 2vw;

      .text-title {
        font-weight: 600;
      }
      .text-description {
        color: hsla(0, 0%, 100%, 0.75);
        font-weight: 600;
      }
    }
  }

  .desktop-screenshot-section {
    flex-basis: calc(60% - var(--spacing) / 2);
    margin-right: var(--spacing);
    flex-grow: 1;
    flex-direction: column;
    position: relative;
    overflow: hidden;
    border-radius: var(--borderRadius);

    .web-screenshot-background {
      background: linear-gradient(-125deg, #e7f9f5, rgba(232, 232, 160, 0) 30%),
        linear-gradient(
          -135deg,
          rgba(17, 17, 46, 0.301961),
          rgba(161, 160, 232, 0) 50%
        ),
        linear-gradient(-45deg, #c7537e, #b3eaff);
      border-radius: var(--borderRadius);
      height: 100%;
      width: 100%;

      -webkit-animation: filter-animation 8s ease-in-out infinite;
      animation: filter-animation 8s ease-in-out infinite;
      -webkit-animation-direction: alternate;
      animation-direction: alternate;
      position: absolute;
    }

    .web-screenshot-content {
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: center;
      width: 100%;
      padding: 5vw 4vw;
      z-index: 10;
    }

    .web-screenshot {
      background-color: #000;
      border-radius: var(--borderRadius);
      box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3), 0 10px 30px rgba(0, 0, 0, 0.3);
      height: auto;
      max-width: 820px;
      width: 100%;
      position: relative;
      z-index: 2;
    }

    .text-container {
      font-size: 24px;
      line-height: 1.28em;
      margin-bottom: 56px;
      padding: 0 2vw;

      .text-title {
        font-weight: 600;
      }
      .text-description {
        color: hsla(0, 0%, 100%, 0.75);
        font-weight: 600;
      }
    }
  }

  .value-prop-section {
    background: #111;
    padding: 100px;

    width: 100%;

    .value-prop-content {
      max-width: var(--width);
      padding: 0 var(--spacing);
      margin: 0 auto;
    }

    .value-prop-title {
      color: #fff;
      font-weight: 700;
      letter-spacing: -1px;
      margin: 0 0 5px;
      font-size: 40px;
    }

    .value-prop-subtitle {
      color: var(--secondaryColor);
      font-size: 26px;
      font-weight: 600;
      margin: 0;
      margin-bottom: 64px;
    }

    .value-prop-list {
      margin-bottom: 64px;
    }

    .value-prop {
      display: flex;
      font-size: 20px;
      font-weight: 700;
      margin-bottom: 32px;

      .value-badge {
        align-items: center;
        background: red;
        border-radius: var(--borderRadius);
        display: flex;
        font-size: 24px;
        height: 50px;
        justify-content: center;
        width: 50px;
      }

      .text-container {
        margin-left: 24px;
        flex: 1;
      }

      .title-text {
        color: #fff;
      }
      .description-text {
        color: var(--secondaryColor);
      }
    }
  }

  .coming-soon-text {
    border-radius: var(--borderRadius);
    border: 1px solid var(--secondaryColor);
    color: var(--secondaryColor);
    display: inline-flex;
    font-weight: 600;
    margin-bottom: 40px;
    padding: 8px 16px;
  }
}

@media only screen and (max-width: 900px) {
  .landing-page-container {
    .value-prop-section {
      padding: 24px 24px 80px 24px;

      .value-prop-content {
        max-width: auto;
        padding: 0;
        margin: 0;
      }

      .value-prop-title {
        font-size: 32px;
      }

      .value-prop-subtitle {
        font-size: 20px;
      }

      .value-prop-list {
        .value-prop {
          font-size: 18px;
        }
      }
    }
    .page-header {
      padding: 0px;
    }

    .header-banner {
      background: transparent;

      .header-logo-badge {
        font-size: 64px;
        height: 56px;
        line-height: 56px;
        margin-bottom: 0px;
        width: 56px;
      }

      .header-logo-letter {
      }
    }
    .header-description {
      font-size: 32px;
    }
    .header-body {
      font-size: 20px;
      line-height: 24px;
    }

    .screenshot-section {
      display: flex;
      margin-bottom: 60px;
      flex-direction: column-reverse;

      .mobile-screenshot-section {
        margin-bottom: 32px;

        .mobile-screenshot-content {
          padding: 8vw 5vw 5vw;
        }

        .phone-screenshot {
          max-width: 180px;
        }

        .text-container {
          font-size: 20px;
        }
      }
      .desktop-screenshot-section {
        margin-right: 0px;

        .web-screenshot-content {
          flex-direction: column-reverse;

          .text-container {
            margin-bottom: 0px;
            margin-top: 32px;
          }
        }
      }

      .text-container {
        font-size: 20px;
        margin-bottom: 32px;
      }
    }
  }
}

@-webkit-keyframes filter-animation {
  0% {
    -webkit-filter: hue-rotate(0deg);
  }

  50% {
    -webkit-filter: hue-rotate(30deg);
  }

  100% {
    -webkit-filter: hue-rotate(0deg);
  }
}

@keyframes filter-animation {
  0% {
    filter: hue-rotate(0deg);
  }

  50% {
    filter: hue-rotate(30deg);
  }

  100% {
    filter: hue-rotate(0deg);
  }
}
