.tracklist {
  width: 100%;

  .tracks-label-container {
    align-items: center;
    display: grid;
    grid-template-columns: 0.3fr 7fr 2fr 2.2fr;
    margin-bottom: 8px;
    padding: 0px 56px 0px 24px;

    .tracks-label {
      align-items: center;
      color: #b3b3b3;
      display: flex;
      font-size: 12px;
      font-weight: 600;
      height: 24px;
      padding-left: 3px;
      text-transform: uppercase;
    }

    .options-label {
      display: flex;
      justify-content: flex-end;
    }
  }

  .track-container {
    margin-bottom: 40px;
    padding-top: 8px;
    animation: fadeInAnimation ease 0.2s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;

    @keyframes fadeInAnimation {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
  }

  .empty-state-container {
    align-items: center;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    margin-right: 232px;
    margin-top: 40px;
    overflow: hidden;
    padding: 16px;
    position: relative;
    animation: fadeInAnimation ease 0.2s;
    animation-iteration-count: 1;

    @keyframes fadeInAnimation {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }

    .empty-info {
      align-items: center;
      display: flex;
      flex-direction: column;
      text-align: center;
      z-index: 10;

      .button {
        padding: 0px 24px;
      }
    }

    .empty-state-title {
      color: #fff;
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 8px;
      margin-top: 32px;
    }

    .empty-state-text {
      color: #b3b3b3;
      font-size: 16px;
      margin-bottom: 24px;
    }

    .empty-state-icon {
      color: #000;
      display: flex;
      font-size: 600px;
      margin-top: -120px;
    }
  }

  .hide-mobile {
    display: inline-flex;
  }
}

@media only screen and (max-width: 1000px) {
  .tracklist {
    .hide-mobile {
      display: none;
    }

    .tracks-label-container {
      display: none;
    }

    .empty-state-container {
      margin-right: 0px;
    }
  }
}
