.landing-top-bar {
  align-items: center;
  display: flex;
  height: 60px;
  justify-content: center;
  padding: 0px 48px;
  position: absolute;
  width: 100%;
  z-index: 1000;

  .top-bar-container {
    display: flex;
    justify-content: space-between;
    position: relative;
    width: 100%;
  }

  .left {
    .logo {
      font-family: "New-York-To-Las-Vegas";
      font-weight: 400;
      font-size: 36px;

      &:hover {
        color: #fff;
      }
    }
  }
  .right {
    align-items: center;
    display: flex;

    .login-button {
      color: #fff;
      cursor: pointer;
      font-size: 14px;
      font-weight: 600;
      margin-right: 40px;

      &:hover {
        transition: 0.24s linear;
        transform: scale(1.03);
      }
    }

    .signup-button {
      border-color: #fff;
    }

    .profile-image {
      align-items: center;
      background-color: #181818;
      background-position: center;
      background-size: cover;
      color: #b3b3b3;
      cursor: pointer;
      border-radius: 100%;
      display: flex;
      font-size: 12px;
      font-weight: 600;
      height: 40px;
      justify-content: center;
      margin-left: 24px;
      text-transform: uppercase;
      width: 40px;

      &:hover {
        transition: 0.24s linear;
        transform: scale(1.03);
      }
    }
  }
}

.scrolled-top-bar {
  align-items: center;
  background-color: rgba(22, 22, 26, 0.7);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  display: flex;
  height: 60px;
  z-index: 3;
  backdrop-filter: blur(5px);
  padding: 0px 48px;
  position: fixed;
}

@media only screen and (max-width: 660px) {
  .top-bar {
    width: 100%;
  }

  .top-bar-container {
    padding: 0px 16px;

    .left {
      .logo {
        font-size: 28px;
      }
    }

    .right {
      .login-button {
        margin-right: 0px;
      }

      .signup-button {
        display: none;
      }
    }
  }
}

@media only screen and (max-width: 900px) {
  .landing-top-bar {
    padding: 24px 16px;
  }
}
