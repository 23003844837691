.sidebar {
  background-color: #000;
  height: 100vh;
  padding: 16px 16px 0px 16px;
  position: fixed;
  width: 232px;

  .sidebar-heading {
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    margin-left: 8px;

    margin-top: 48px;
    margin-bottom: 8px;
  }

  .logo-title {
    font-family: "New-York-To-Las-Vegas";
    font-weight: 400;
    font-size: 36px;
    margin-left: 16px;
  }

  .account-button-active {
    background-color: #181818;
    color: #fff;

    .user-image {
      border-radius: 100%;
      height: 32px;
      width: 32px;
    }
  }

  .nav-section {
    margin-top: 56px;

    .nav-title {
      color: #b3b3b3;
      font-size: 14px;
      font-weight: 600;
      margin-bottom: 16px;
      padding-left: 16px;
      text-transform: uppercase;
    }

    .nav-item {
      align-items: center;
      border-radius: 4px;
      color: #b3b3b3;
      cursor: pointer;
      display: flex;
      height: 40px;
      margin-bottom: 4px;
      padding-left: 12px;
      width: 100%;

      &:hover {
        color: #fff;
        transition: 0.2s linear;
      }

      .icon {
        display: flex;
        font-size: 24px;
        position: relative;

        .unread-badge {
          align-items: center;
          background-color: var(--primary-color);
          border-radius: 100%;
          color: #fff;
          display: flex;
          font-size: 10px;
          font-weight: 500;
          height: 16px;
          justify-content: center;
          right: -4px;
          position: absolute;
          width: 16px;
          top: -4px;
          z-index: 10;
        }
      }

      .page-name {
        font-size: 16px;
        font-weight: 500;
        margin-left: 16px;
      }
    }

    .nav-item-active {
      background-color: #121212;
      color: #fff;
    }
  }

  .account-button {
    align-items: center;
    background-color: #060606;

    bottom: 8px;
    color: #b3b3b3;
    cursor: pointer;
    display: flex;
    height: 64px;
    justify-content: space-between;
    left: 0;
    padding: 0px 16px;
    position: absolute;
    width: 100%;

    &:hover {
      color: #fff;
    }

    .info {
      align-items: center;
      display: flex;

      .user-button {
        align-items: center;

        border-radius: 100%;
        color: #fff;
        display: flex;
        font-size: 32px;
        height: 28px;
        justify-content: center;
        width: 28px;
      }

      .name {
        font-size: 16px;
        font-weight: 500;
        margin-left: 12px;
      }
    }

    .buttons {
      align-items: center;
      display: flex;
      font-size: 22px;

      .notifications-button {
        display: flex;
      }

      .settings-button {
        display: flex;
        margin-left: 16px;
      }
    }
  }
}

.sidebar-hidden {
  display: none;
}

.sidebar-logged-out {
  left: 0px;

  .logo-title {
    margin-left: 16px;
  }

  .logged-out-info {
    padding: 16px;

    .description-text {
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 24px;
    }

    .button {
      margin-bottom: 16px;
    }
  }
}

@media only screen and (max-width: 1000px) {
  .sidebar {
    height: calc(100% - 100px);
  }
}
