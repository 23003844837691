.upload-track-modal {
  background-color: #000;
  border-radius: 4px;
  padding: 24px 8px;

  .content-top {
    display: flex;
    justify-content: space-between;
    padding: 0px 16px;
  }

  .title {
    font-size: 18px;
    font-weight: bold;
  }

  .divider {
    background-color: #282828;
    height: 1px;
    margin: 24px 0px;
    width: 100%;
  }

  .share-content-middle {
    padding: 0px 16px;
  }

  .share-form {
    margin-bottom: 24px;

    label {
      color: #b3b3b3;
      font-size: 12px;
      font-weight: 600;
      text-transform: uppercase;
    }

    .form-box {
      align-items: center;
      display: flex;
      margin-top: 8px;
    }

    .link-access-text {
      margin-left: 16px;
    }

    .sharing-link-field {
      background-color: #282828;
      color: #b3b3b3;
    }

    .text-field {
      flex: 1;
    }

    .invite-button {
      margin-left: 8px;
      width: 120px;
    }
  }
}
