.upload-status-modal {
  background-color: #000;
  border-radius: 4px;
  max-height: 360px;
  padding: 24px;

  .content-top {
    display: flex;
    justify-content: space-between;
  }

  .title {
    font-size: 18px;
    font-weight: bold;
  }

  .content-middle {
    max-height: calc(360px - 96px);
    overflow-y: scroll;
  }

  .uploading-track {
    align-items: center;
    background-color: #282828;
    border-radius: 4px;
    display: flex;
    height: 56px;
    justify-content: space-between;
    margin-bottom: 8px;
    padding: 0px 24px 0px 16px;
    width: 100%;

    .uploading-track-left {
      align-items: center;
      display: flex;

      .updating-icon {
        align-items: center;
        color: var(--primary-color);
        display: flex;
        font-size: 26px;
        margin-right: 16px;
        -webkit-animation-name: spin;
        -webkit-animation-duration: 4000ms;
        -webkit-animation-iteration-count: infinite;
        -webkit-animation-timing-function: linear;
        -moz-animation-name: spin;
        -moz-animation-duration: 4000ms;
        -moz-animation-iteration-count: infinite;
        -moz-animation-timing-function: linear;
        -ms-animation-name: spin;
        -ms-animation-duration: 4000ms;
        -ms-animation-iteration-count: infinite;
        -ms-animation-timing-function: linear;

        animation-name: spin;
        animation-duration: 4000ms;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
      }

      .uploaded-icon {
        align-items: center;
        color: #1b9c26;
        display: flex;
        font-size: 26px;
        margin-right: 16px;
      }
    }

    .track-info {
      .track-name {
        font-size: 16px;
        font-weight: 500;
      }
      .track-type {
        color: #fff;
        font-weight: 500;
      }
    }
    .upload-status {
      font-weight: 500;
    }
  }
}
