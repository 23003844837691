.moveFolderModal {
  display: inline-block;
  width: 100%;

  .content-top {
    display: flex;
    justify-content: space-between;
    padding: 24px 24px 8px 24px;
  }

  .modal-title {
    font-size: 20px;
    font-weight: bold;
  }

  .fa-times {
    cursor: pointer;

    &:hover {
      opacity: 0.6;
    }
  }

  .folders-breadcrumbs {
    border-bottom: 1px solid #282828;
    color: #b3b3b3;
    display: flex;
    font-size: 12px;
    font-weight: 600;
    padding: 0px 24px 16px 24px;
    text-transform: uppercase;

    .library-breadcrumb {
    }

    .back-breadcrumb {
      cursor: pointer;
      display: flex;

      &:hover {
        color: #fff;
      }

      .back-icon {
        display: flex;
        margin-right: 4px;
      }
    }
  }

  .content-middle {
    flex: 1;
    max-height: calc(100vh - 300px);
    margin-top: -1px;
    overflow-y: auto;
  }

  .content-middle-form {
    padding: 0px 24px 24px 24px;
  }

  .empty-folder-state-container {
    align-items: center;
    display: flex;
    font-weight: 500;
    flex-direction: column;
    height: 200px;
    justify-content: center;
    width: 100%;

    .empty-text {
      margin-bottom: 16px;
    }
  }

  .content-bottom {
    align-items: center;
    background-color: #000;
    border-radius: 0px 0px 4px 4px;
    border-top: 1px solid #282828;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    padding: 24px;

    width: 100%;

    .selected-folder-text {
      .selected-label {
        align-items: center;
        color: #b3b3b3;
        display: flex;
        font-size: 12px;
        font-weight: 600;
        margin-bottom: 4px;
        text-transform: uppercase;
      }
      .selected-folder-name {
        font-weight: 500;
      }
    }

    .moveFolder-button {
      align-items: center;
      cursor: pointer;
      background-color: var(--primary-color);
      border-radius: 40px;
      display: flex;
      font-weight: 600;
      height: 40px;
      justify-content: center;
      width: 96px;

      &:hover {
        background-color: #fff;
        color: #000;
        transition: 0.24s linear;
      }
    }

    .moveFolder-button-disabled {
      align-items: center;
      background-color: #282828;
      border-radius: 40px;
      cursor: not-allowed;
      display: flex;
      font-weight: 600;
      height: 40px;
      justify-content: center;
      width: 96px;
    }
  }
}
