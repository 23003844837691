/* Global Styles */

.alert {
  align-items: center;
  background: var(--light-color);
  color: #333;
  display: flex;
  justify-content: center;
  padding: 0.8rem;
  margin: 0 auto;
  position: fixed;

  text-align: center;
  z-index: 10000;
  width: 100%;
}

.alert-primary {
  background: var(--primary-color);
  color: #fff;
}

.alert-light {
  background: var(--light-color);
  color: #333;
}

.alert-dark {
  background: var(--dark-color);
  color: #fff;
}

.alert-danger {
  background: var(--danger-color);
  color: #fff;
}

.alert-success {
  background: var(--success-color);
  color: #fff;
}

.alert-white {
  background: #fff;
  color: #333;
  border: #ccc solid 1px;
}
