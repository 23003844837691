.profile-picture-settings {
  align-items: center;
  display: flex;
  margin-bottom: 32px;

  .image-upload {
    align-items: center;
    background-color: #282828;
    background-position: center;
    background-size: cover;
    border-radius: 100%;
    display: flex;
    font-size: 12px;
    font-weight: 600;
    height: 40px;
    justify-content: center;
    margin-right: 24px;
    text-align: center;
    width: 40px;

    .image-label {
      align-items: center;
      color: #b3b3b3;
      display: flex;
      justify-content: center;
    }
  }
  .profile-image-info {
    .profile-name {
      font-size: 18px;
      font-weight: 500;
      margin-bottom: 4px;
    }
    .profile-image-change-link {
      color: var(--primary-color);
      font-size: 14px;
      font-weight: 500;
    }
  }

  .inputfile {
    display: none;
  }

  .inputfile + label {
    cursor: pointer;
    height: 100%;
    width: 100%;
  }

  .loader-icon {
    align-items: center;
    display: flex;
    height: 40px;
    margin-left: 8px;
    width: 40px;
  }
}
