.permissions-menu-container {
  background: #090909;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.05);
  border: 1px solid #282828;
  border-radius: 4px;
  padding: 8px 0px;
  position: absolute;
  right: 0px;
  top: -24px;
  width: 160px;
  z-index: 1000;

  .menu-item {
    align-items: center;
    cursor: pointer;
    display: flex;
    height: 40px;
    padding: 0px 16px;
    width: 100%;

    &:hover {
      background-color: #000;
      color: #fff;
    }
  }

  .permissions-divider {
    background-color: #282828;
    height: 1px;
    width: 100%;
  }
}
