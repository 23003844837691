.folders-page {
  .top-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
    width: 100%;
  }

  .page-title {
    font-size: 64px;
    font-weight: 600;
  }

  .create-folder-button {
    display: inline-flex;
    margin-bottom: 16px;
    margin-top: 16px;
  }

  .tab-container {
    display: flex;
    height: 40px;
    width: 100%;
    .tab {
      cursor: pointer;
      font-size: 14px;
      font-weight: 600;
      margin-right: 40px;
      text-transform: uppercase;

      &:hover {
        color: #fff;
      }
    }
    .tab-active {
      color: #fff;
      border-bottom: 1px solid #fff;
    }
  }

  .folders-container {
    width: 100%;

    .create-folder-button {
      align-items: center;
      display: flex;
      cursor: pointer;
      border-radius: 4px;
      border: 2px solid #282828;
      float: left;
      font-weight: 500;
      height: 160px;
      justify-content: center;
      margin-bottom: 16px;
      margin-right: 16px;
      width: 242px;

      &:hover {
        background-color: #060606;
      }
    }
  }

  .empty-state-container {
    align-items: center;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    margin-top: 40px;
    overflow: hidden;
    padding: 16px;
    position: relative;
    animation: fadeInAnimation ease 0.2s;
    animation-iteration-count: 1;

    @keyframes fadeInAnimation {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }

    .empty-info {
      align-items: center;
      display: flex;
      flex-direction: column;
      margin-right: 232px;
      z-index: 10;

      .button {
        padding: 0px 24px;
      }
    }

    .empty-state-title {
      color: #fff;
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 8px;
    }

    .empty-state-text {
      color: #b3b3b3;
      font-size: 16px;
      margin-bottom: 32px;
    }

    .empty-state-icon {
      color: #000;
      display: flex;
      font-size: 600px;
      margin-top: -120px;
    }

    .loading-icon {
      margin-right: 232px;
    }
  }
}

@media only screen and (max-width: 660px) {
  .folders-page {
    .folders-container {
      .create-folder-button {
        width: 100%;
      }
    }
    .page-title {
      font-size: 32px;
    }

    .create-folder-button {
      display: none;
    }
  }

  .folders-page .empty-state-container .loading-icon {
    margin-right: 0px;
  }

  .folders-page .empty-state-container .empty-info {
    margin-right: 0px;
  }
}
