.ReactModal__Overlay {
  z-index: 100;
  opacity: 0;
  transition: opacity 0.2s ease-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.modal {
  height: 100%;
  max-height: 100vh;
  width: 100%;

  .close-bar {
    align-items: center;
    display: flex;
    width: "100%";
  }
  .close-icon {
    color: #b8b8b8;
    cursor: pointer;
    float: right;
    font-size: 24px;
    margin-left: auto;

    &:hover {
      color: #fff;
    }
  }

  .title {
    font-size: 16px;
    font-weight: bold;
  }

  .center-text {
    color: #777;
    font-size: 14px;
    text-align: center;
  }
}

@media only screen and (max-width: 660px) {
  .modal {
    width: 100%;
  }
}
