.audioPlayer {
  align-items: center;
  background: #181818;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.05);
  bottom: 0px;
  border-top: 1px solid #000;
  display: flex;
  flex-direction: row;
  height: 90px;
  justify-content: space-between;
  padding: 0px 16px 2px 16px;
  position: fixed;
  width: 100%;
  z-index: 100;

  .left-container {
    align-items: center;
    display: flex;
    height: 100%;
    width: 25%;

    .track-info {
      flex: 1;
      margin-top: 4px;
      overflow: hidden;
      padding-right: 48px;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .track-image {
      align-items: center;
      background-color: #2a2a2a;
      background-position: center;
      background-size: cover;
      border-radius: 2px;
      box-shadow: 0px 2px 8px 1px rgba(0, 0, 0, 0.1);
      color: #181818;
      display: flex;
      font-size: 26px;
      height: 56px;
      justify-content: center;
      margin-right: 16px;
      position: 50%;
      width: 56px;
    }

    .track-name {
      color: #fff;
      font-size: 14px;
      font-weight: 500;
      max-width: calc(30% - 64px);

      &:hover {
        text-decoration: underline;
      }
    }

    .track-username {
      color: #b3b3b3;
      font-size: 14px;
      font-weight: 500;
      margin-top: 2px;
    }
  }

  .middle-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 56px;
    padding: 0px 40px;
    width: 50%;

    .mobile-name-container {
      display: none;
    }

    .control-buttons {
      align-items: center;
      display: flex;
      justify-content: center;
      margin-bottom: 8px;
      width: 100%;

      .back-button {
        align-items: center;
        color: #b3b3b3;
        cursor: pointer;
        display: flex;
        font-size: 18px;

        &:hover {
          color: #fff;
          transition: 0.2s linear;
        }
      }

      .back-button-disabled {
        align-items: center;
        color: #535353;
        display: flex;
        font-size: 18px;
      }

      .next-button {
        align-items: center;
        color: #b3b3b3;
        cursor: pointer;
        display: flex;
        font-size: 18px;

        &:hover {
          color: #fff;
          transition: 0.2s linear;
        }
      }

      .next-button-disabled {
        align-items: center;
        color: #535353;
        display: flex;
        font-size: 18px;
      }

      .play-button {
        align-items: center;
        border: 1px solid #fff;
        border-radius: 100%;
        color: #fff;
        cursor: pointer;
        display: flex;
        font-size: 16px;
        height: 32px;
        justify-content: center;
        margin: 0px 24px;
        width: 32px;

        &:hover {
          border: 1px solid #fff;
          color: #fff;
          transition: 0.2s linear;
          transform: scale(1.06);
        }

        .play-icon {
          display: flex;
          margin-left: 1px;
        }

        .pause-icon {
          display: flex;
        }
      }

      .play-button-disabled {
        align-items: center;
        border: 1px solid #535353;
        border-radius: 100%;
        color: #535353;
        display: flex;
        font-size: 16px;
        height: 32px;
        justify-content: center;
        margin: 0px 24px;
        width: 32px;

        .play-icon {
          display: flex;
          margin-left: 1px;
        }
      }
    }

    .progressBar-container {
      align-items: center;
      display: flex;
      margin-bottom: 12px;
      width: 100%;
    }

    .seekBar-container {
      flex: 1;
      margin: 0px 16px;
    }
  }

  .right-container {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    height: 100%;
    padding-right: 16px;
    width: 25%;

    .like-button {
      font-size: 22px;
      margin-left: 32px;
    }

    .share-button {
      font-size: 22px;
      margin-left: 32px;
    }

    .download-button {
      font-size: 26px;
      margin-left: 32px;
    }

    .volume-icon {
      color: #b3b3b3;
      display: flex;
      font-size: 22px;
      margin-right: 8px;
    }

    .volume-slider {
      cursor: grab;
      width: 80px;
    }
  }

  .control-button {
    align-items: center;
    background-color: var(--primary-color);
    border-radius: 100%;
    color: #fff;
    cursor: pointer;
    display: flex;
    font-size: 18px;
    height: 32px;
    justify-content: center;
    width: 32px;

    &:hover {
      background-color: #fff;
      color: #282828;
      transition: 0.2s linear;
    }
  }

  .shuffle-button {
    color: #b3b3b3;
    cursor: pointer;
    display: flex;
    font-size: 20px;
    margin-right: 24px;

    &:hover {
      color: var(--primary-color);
      transition: 0.2s linear;
      transform: scale(1.06);
    }
  }

  .shuffle-button-active {
    color: var(--primary-color);
  }

  .repeat-button {
    color: #b3b3b3;
    cursor: pointer;
    display: flex;
    font-size: 20px;
    margin-left: 24px;

    &:hover {
      color: var(--primary-color);
      transition: 0.2s linear;
      transform: scale(1.06);
    }
  }

  .repeat-playlist-icon {
    color: var(--primary-color);
    display: flex;
  }

  .repeat-one-icon {
    color: var(--primary-color);
    position: relative;
    display: flex;

    .repeat-one-badge {
      align-items: center;
      background-color: var(--primary-color);
      border: 1px solid #282828;
      border-radius: 100%;
      color: #000;
      display: flex;
      font-size: 7px;
      font-weight: 600;
      height: 12px;
      justify-content: center;
      position: absolute;
      right: -1px;
      top: -1px;
      width: 12px;
      z-index: 10;
    }
  }

  .info-box {
    display: flex;
    flex-direction: column;
    height: 60px;
    justify-content: space-between;
    margin-left: 16px;
    position: relative;
    width: calc(100% - 64px);

    .title {
      color: #fff;
      font-size: 14px;
      font-weight: 500;
      height: 24px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 100%;
    }

    .progressBar {
      margin-bottom: 8px;
      position: absolute;
      top: 24px;
      width: 100%;
    }

    .time-container {
      display: flex;
      justify-content: space-between;
      width: 100%;

      .time {
        color: #fff;
        font-size: 12px;
        font-weight: 500;
      }
    }
  }
}

.playerMargin {
  margin-bottom: 40px;
}

.audioPlayer-hidden {
  display: none;
}

@media only screen and (max-width: 1000px) {
  .audioPlayer {
    height: 100px;
    padding: 8px 16px 16px 16px;
    width: 100%;

    .left-container {
      display: none;
    }

    .middle-container {
      flex-direction: column-reverse;
      height: 100%;
      padding: 0px;
      width: 100%;

      .control-buttons {
        margin-bottom: 0px;
      }

      .mobile-name-container {
        display: block;
        font-weight: 500;
        margin-bottom: 2px;
        overflow: hidden;
        padding: 0px 8px;
        text-align: center;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 100%;
      }

      .progressBar-container {
        margin-bottom: 6px;
      }
    }

    .right-container {
      display: none;
    }
  }
}
