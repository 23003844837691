.submissions-list-page {
  .page-title {
    font-size: 64px;
    font-weight: 600;
  }

  .page-top-container {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }

  .page-details {
    align-items: center;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .submissions-list {
    width: 100%;

    .tab-container {
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
      display: flex;
      height: 40px;
      margin-bottom: 24px;
      width: 100%;

      .tab {
        color: #b3b3b3;
        cursor: pointer;
        font-size: 18px;
        font-weight: 500;
        height: 40px;
        margin-bottom: -2px;
        margin-right: 40px;

        &:hover {
          color: #fff;
        }
      }
      .tab-active {
        border-bottom: 1px solid #fff;
        color: #fff;
        z-index: 10;
      }
    }

    .active-tab {
      border-bottom: 2px solid var(--primary-color);
      color: var(--primary-color);
    }

    .share-link-container {
      display: flex;

      .copy-link-button {
        margin-left: 16px;
        width: 160px;
      }
    }

    .empty-state-container {
      align-items: center;
      border-radius: 4px;
      display: flex;
      flex-direction: column;
      height: 240px;
      margin-top: 40px;
      overflow: hidden;
      padding: 16px;
      position: relative;

      .empty-info {
        align-items: center;
        display: flex;
        flex-direction: column;
        margin-right: 232px;
        position: absolute;
        z-index: 10;

        .button {
          padding: 0px 24px;
        }
      }

      .empty-state-title {
        color: #fff;
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 8px;
        margin-top: 32px;
      }

      .empty-state-text {
        color: #b3b3b3;
        font-size: 16px;
        margin-bottom: 32px;
      }

      .loading-icon {
        margin-right: 232px;
      }
    }
  }

  .submissions-list-hidden {
    display: none;
  }
}

@media only screen and (max-width: 660px) {
  .submissions-list-page {
    .page-title {
      font-size: 32px;
      font-weight: 600;
    }

    .page-details {
      .button-right {
        display: none;
      }
    }

    .submission-list {
      .empty-state-container {
        .empty-info {
          margin-right: 0px;
        }
      }
    }

    .submissions-list .empty-state-container .loading-icon {
      margin-right: 0px;
    }
  }
}
