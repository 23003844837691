.notification-menu-container {
  background: #090909;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.05);
  border: 1px solid #282828;
  border-radius: 4px;

  padding: 8px 0px;
  position: absolute;
  right: 56px;
  top: 48px;
  width: 400px;
  z-index: 1000;
  animation: fadeInAnimation ease 0.16s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;

  @keyframes fadeInAnimation {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .menu-top {
    border-bottom: 1px solid #282828;
    display: flex;
    justify-content: space-between;
    padding: 16px;

    .menu-title {
      align-items: center;
      color: #b3b3b3;
      display: flex;
      font-size: 12px;
      font-weight: 600;
      text-transform: uppercase;
    }

    .enable-push-button {
      padding: 0px 16px;
    }
  }

  .notifications-list {
    max-height: 400px;
    min-height: 200px;
    overflow-y: overlay;
  }

  .empty-notifications {
    align-items: center;
    color: #b3b3b3;
    display: flex;
    height: 200px;
    flex-direction: column;
    justify-content: center;
    padding: 56px;
    text-align: center;

    .empty-title {
      color: #fff;
      font-size: 14px;
      font-weight: 600;
      margin-bottom: 8px;
    }
  }

  .loader-icon {
    align-items: center;
    display: flex;
    height: 200px;
    justify-content: center;
  }
}
