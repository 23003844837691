.upload-modal-track {
  align-items: center;
  background-color: #181818;
  border-radius: 4px;
  display: flex;
  min-height: 48px;
  justify-content: space-between;
  margin-top: 8px;
  position: relative;
  width: 100%;

  .left {
    align-items: center;
    display: flex;
    padding-left: 16px;

    .track-icon {
      align-items: center;
      border: 1px solid var(--primary-color);
      border-radius: 4px;
      color: var(--primary-color);
      display: flex;
      height: 26px;
      justify-content: center;
      width: 26px;
      margin-right: 24px;
    }

    .loading-icon {
      margin-right: 24px;
    }

    .info {
      width: 100%;
      margin-right: 24px;

      .track-name {
        font-size: 14px;
        font-weight: 500;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 400px;
        white-space: nowrap;
      }

      .track-tags {
        color: #b3b3b3;
        font-size: 12px;
      }
    }
  }

  .right {
    align-items: center;
    display: flex;
    padding-right: 16px;

    .uploading-text {
      color: #b3b3b3;
    }

    .edit-button {
      cursor: pointer;
      text-decoration: underline;

      &:hover {
        color: #fff;
      }
    }
    .remove-button {
      color: #b3b3b3;
      cursor: pointer;
      display: flex;
      font-size: 22px;
      margin-left: 40px;

      &:hover {
        color: #fff;
      }
    }
  }
}
