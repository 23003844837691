.edit-folder-image-container {
  .image-button-container {
    margin-top: 24px;
    bottom: 16px;
    display: flex;
    right: 16px;
  }

  .folder-image-label {
    color: #b3b3b3;
    font-size: 12px;
    font-weight: 600;
    margin-bottom: 8px;
    text-transform: uppercase;
  }

  .image-upload-button {
    align-items: center;
    background: var(--primary-color);
    border: none;
    border-radius: 4px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    color: #fff;
    cursor: pointer;
    display: flex;
    font-size: 14px;
    font-weight: 500;
    height: 32px;
    margin-left: 24px;
    padding: 0px 16px;
    justify-content: center;
    text-decoration: none;
    -webkit-appearance: none;
  }

  .image-remove-button {
    align-items: center;

    color: #b3b3b3;
    cursor: pointer;
    display: flex;
    font-size: 14px;

    &:hover {
      color: #fff;
    }
  }

  .no-profile-picture {
    color: #b3b3b3;
    font-size: 12px;
    font-weight: 600;
    padding: 16px;
    text-transform: uppercase;
    text-align: center;
  }

  .edit-profile-image {
    align-items: center;
    background-color: #0b0b0b;
    background-position: center;
    background-size: cover;
    border: 1px dashed #282828;
    height: 180px;
    position: relative;
    display: flex;
    justify-content: center;
    overflow: hidden;
    width: 180px;

    .icon-container {
      align-items: center;
      color: #121212;
      display: flex;
      font-size: 60px;
      height: 100%;
      justify-content: center;
      position: absolute;
      width: 100%;
    }

    .inputfile {
      display: none;
    }

    .image-upload {
      align-items: center;
      display: flex;
      position: absolute;
      height: 100%;
      justify-content: center;
      text-align: center;
      width: 100%;
    }

    .image-upload-hidden {
      display: none;
    }

    .inputfile + label {
      align-items: center;
      background-color: rgba(0, 0, 0, 0.8);
      color: #fff;
      display: flex;
      height: 100%;
      justify-content: center;
      padding: 16px;
      width: 100%;
    }

    .inputfile + label {
      cursor: pointer;
      /* "hand" cursor */
    }

    .image-preview {
      background-size: cover;
      height: 100%;
      position: absolute;
      width: 100%;
    }

    .uploading-container {
      align-items: center;
      background-color: rgba(0, 0, 0, 0.8);
      color: #fff;
      display: flex;
      height: 100%;
      justify-content: center;
      padding: 16px;
      position: absolute;
      width: 100%;
    }
  }
}
