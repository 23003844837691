.submission-container {
  align-items: center;
  display: flex;
  justify-content: space-between;
  background-color: #121212;
  border-radius: 4px;
  height: 64px;
  margin-top: 16px;
  padding: 0px 24px;
  width: 100%;

  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }

  .left {
    align-items: center;
    display: flex;
  }

  .user-icon-container {
    position: relative;

    .unread-badge {
      background-color: var(--primary-color);
      border-radius: 100%;
      height: 10px;
      right: 0;
      position: absolute;
      width: 10px;
    }
  }
  .user-icon {
    align-items: center;
    background-color: #515151;
    background-position: center;
    background-size: cover;
    border-radius: 100%;
    display: flex;
    color: #fff;
    font-size: 12px;
    font-weight: 600;
    height: 40px;
    justify-content: center;
    line-height: 36px;
    text-transform: uppercase;
    width: 40px;
  }

  .submission-info {
    margin-left: 16px;

    .title {
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 2px;
    }

    .subtext {
      color: #b3b3b3;
    }
  }

  .right {
    .date {
      color: #b3b3b3;
      font-size: 13px;
    }
  }
}

@media only screen and (max-width: 660px) {
  .submission-container {
    background-color: transparent;
    margin-top: 0px;
    padding: 0px;

    &:hover {
      background-color: transparent;
    }

    .submission-info {
      .subtext {
        font-size: 13px;
      }
    }

    .right {
      .open-button {
        display: none;
      }
    }
  }
}
