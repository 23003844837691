.change-password-form {
  label {
    color: #b9bbbe;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
  }

  .text-field {
    margin-bottom: 24px;
    margin-top: 8px;
  }
}
