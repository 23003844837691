.analytics-page {
  .top-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
    width: 100%;
  }

  .page-title {
    font-size: 64px;
    font-weight: 600;
  }

  .page-label {
    align-items: center;
    color: #fff;
    display: flex;
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 24px;
  }

  .page-section {
    margin-bottom: 40px;
  }

  .tracks-label-container {
    align-items: center;
    display: grid;
    grid-template-columns: 0.3fr 5fr 2fr 2fr 2.2fr;
    margin-bottom: 8px;
    padding: 0px 56px 0px 24px;

    .tracks-label {
      align-items: center;
      color: #b3b3b3;
      display: flex;
      font-size: 12px;
      font-weight: 600;
      height: 24px;
      padding-left: 3px;
      text-transform: uppercase;
    }

    .options-label {
      display: flex;
      justify-content: flex-end;
    }
  }

  .track-container {
    margin-bottom: 40px;
    padding-top: 8px;
    animation: fadeInAnimation ease 0.2s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;

    @keyframes fadeInAnimation {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
  }
}

@media only screen and (max-width: 1000px) {
  .analytics-page {
    .tracks-label-container {
      display: none;
    }
  }
}
