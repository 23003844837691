.top-bar {
  align-items: center;
  display: flex;
  height: 64px;
  justify-content: center;
  padding: 0px 40px 0px 40px;
  position: fixed;
  width: calc(100% - 232px);
  z-index: 100;

  .top-bar-background {
    width: 100%;
    height: 100%;
    position: absolute;
  }

  .content-container {
    align-items: center;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .top-bar-left {
    align-items: center;
    display: flex;

    .mobile-hamburger-menu {
      display: none;
    }

    .back-button {
      align-items: center;
      background-color: rgba(255, 255, 255, 0.1);
      border-radius: 100%;
      color: rgba(255, 255, 255, 0.6);
      cursor: pointer;
      display: flex;
      font-size: 20px;
      height: 32px;
      justify-content: center;
      margin-right: 16px;
      width: 32px;
      z-index: 100;

      &:hover {
        color: #fff;
        opacity: 1;
        transition: 0.2s linear;
        transform: scale(1.06);
      }
    }
    .forward-button {
      align-items: center;
      background-color: rgba(255, 255, 255, 0.1);
      color: rgba(255, 255, 255, 0.6);
      border-radius: 100%;
      cursor: pointer;
      display: flex;
      font-size: 20px;
      height: 32px;
      justify-content: center;
      width: 32px;
      z-index: 100;

      &:hover {
        color: #fff;
        opacity: 1;
        transition: 0.2s linear;
        transform: scale(1.06);
      }
    }

    .forward-button-inactive {
      cursor: disabled;
    }
  }
  .top-bar-right {
    position: relative;
    align-items: center;
    display: flex;
    .topbar-upload {
      &:hover {
        transition: 0.24s linear;
        transform: scale(1.03);
      }
    }

    .profile-image {
      align-items: center;
      background-color: #181818;
      background-position: center;
      background-size: cover;
      color: #b3b3b3;
      cursor: pointer;
      border-radius: 100%;
      display: flex;
      font-size: 12px;
      font-weight: 600;
      height: 40px;
      justify-content: center;
      margin-left: 24px;
      text-transform: uppercase;
      width: 40px;

      &:hover {
        transition: 0.24s linear;
        transform: scale(1.03);
      }
    }

    .notification-button {
      align-items: center;
      background-color: rgba(255, 255, 255, 0.1);
      color: #fff;
      border-radius: 100%;
      cursor: pointer;
      display: flex;
      font-size: 20px;
      height: 40px;
      justify-content: center;
      margin-left: 24px;
      position: relative;
      width: 40px;

      &:hover {
        color: #fff;
        opacity: 1;
        transition: 0.2s linear;
        transform: scale(1.06);
      }

      .unread-badge {
        align-items: center;
        background-color: #e0245e;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
        border-radius: 50px;
        color: #fff;
        display: flex;
        font-size: 10px;
        font-weight: 500;
        height: 18px;
        justify-content: center;
        min-width: 18px;
        right: -6px;
        padding: 0px 6px;
        position: absolute;

        top: -4px;
        z-index: 10;
      }
    }
    .notification-button-active {
      color: #fff;
    }
  }
}

.top-bar-hidden {
  display: none;
}

@media only screen and (max-width: 1000px) {
  .top-bar {
    margin-left: 0;
    padding: 0px 16px;
    position: absolute;
    width: 100%;
    z-index: 100;

    .top-bar-background {
      display: none;
    }

    .top-bar-left {
      .mobile-hamburger-menu {
        cursor: pointer;
        display: block;
        font-size: 26px;
        margin-right: 24px;

        &:hover {
          color: #fff;
        }
      }

      .back-button {
        display: none;
      }
      .forward-button {
        display: none;
      }
    }
  }
}

@media only screen and (max-width: 660px) {
  .top-bar {
    .top-bar-right {
      .notification-button {
        display: none;
      }
      .topbar-upload {
        display: none;
      }
      .divider {
        display: none;
      }
    }
  }
}
