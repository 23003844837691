.topbar-settings-menu-container {
  background-color: #181818;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.05);

  border-radius: 4px;
  color: #b3b3b3;
  padding: 8px 0px;
  position: absolute;
  right: 0px;
  top: 48px;
  width: 160px;
  z-index: 1000;
  animation: fadeInAnimation ease 0.16s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;

  @keyframes fadeInAnimation {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .menu-item {
    align-items: center;
    cursor: pointer;
    display: flex;
    height: 36px;
    padding: 0px 12px;
    width: 100%;

    &:hover {
      background-color: #2a2a2a;
      color: #fff;
    }
  }
}

@media only screen and (max-width: 660px) {
  .topbar-settings-menu-container {
    right: 0px;

    .account-settings {
      display: none;
    }
  }
}
