.track-modal-comment-input-container {
  background-color: #000;
  border-radius: 0px 0px 4px 4px;
  bottom: 0;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 32px;
  width: 100%;

  .input-top {
    display: flex;
    flex: 1;
  }

  .profile-image {
    align-items: center;
    background-color: #282828;
    background-position: center;
    background-size: cover;
    color: #b3b3b3;
    border-radius: 100%;
    display: flex;
    font-size: 12px;
    font-weight: 600;
    height: 36px;
    justify-content: center;
    margin-right: 16px;
    text-transform: uppercase;
    width: 36px;
  }

  .comment-text-field {
    background-color: #121212;
    border: 1px solid #282828;
    border-radius: 4px;
    color: #fff;
    flex: 1;
    font-size: 14px;
    height: 80px;
    margin-bottom: 16px;
    padding: 8px 16px;
    resize: none;
  }

  .button-row {
    display: flex;
    justify-content: flex-end;

    .comment-timestamp {
      align-items: center;
      background-color: #121212;
      border-radius: 4px;
      display: flex;
      font-size: 12px;
      font-weight: 500;
      height: 30px;
      justify-content: space-between;
      line-height: 30px;
      margin-right: 16px;
      padding: 0px 8px;
      width: 88px;

      .comment-timestamp-left {
        align-items: center;
        display: flex;
      }

      .time-icon {
        color: #b3b3b3;
        display: flex;
        font-size: 14px;
        margin-right: 4px;
      }

      .select-timestamp-button {
        cursor: pointer;
        font-size: 18px;
        margin-left: 8px;

        .timestamp-active-icon {
          color: #b3b3b3;
          display: flex;

          &:hover {
            color: #fff;
            transition: 0.24s linear;
            transform: scale(1.06);
          }
        }

        .timestamp-disabled-icon {
          color: #b3b3b3;
          display: flex;

          &:hover {
            color: #fff;
            transition: 0.24s linear;
            transform: scale(1.06);
          }
        }
      }
    }

    .add-comment-button {
      background-color: #e0235e;
      border-radius: 4px;
      color: #fff;
      cursor: pointer;
      font-weight: 500;
      height: 30px;
      line-height: 30px;
      padding: 0px 10px;
    }

    .add-comment-button-disabled {
      background-color: #282828;
      border-radius: 4px;
      color: #b3b3b3;
      cursor: pointer;
      font-weight: 500;
      height: 30px;
      line-height: 30px;
      padding: 0px 10px;
    }
  }
}
