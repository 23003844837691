.status-bar {
  background: #181818;
  border: 1px solid #101010;
  border-radius: 4px;
  height: 64px;
  left: 0;
  margin-left: auto;
  margin-right: auto;
  padding: 0px 24px 0px 16px;
  position: absolute;
  right: 0;
  width: 560px;
  z-index: 1000;

  .upload-status-bar {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: space-between;
  }

  .status-bar-left {
    align-items: center;
    display: flex;

    .updating-icon {
      align-items: center;
      color: var(--primary-color);
      display: flex;
      font-size: 26px;
      margin-right: 16px;
      -webkit-animation-name: spin;
      -webkit-animation-duration: 4000ms;
      -webkit-animation-iteration-count: infinite;
      -webkit-animation-timing-function: linear;
      -moz-animation-name: spin;
      -moz-animation-duration: 4000ms;
      -moz-animation-iteration-count: infinite;
      -moz-animation-timing-function: linear;
      -ms-animation-name: spin;
      -ms-animation-duration: 4000ms;
      -ms-animation-iteration-count: infinite;
      -ms-animation-timing-function: linear;

      animation-name: spin;
      animation-duration: 4000ms;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
    }

    .status-text {
      font-weight: 500;
    }
  }

  .status-bar-right {
    .open-modal-button {
      cursor: pointer;
      font-weight: 500;

      &:hover {
        color: #fff;
      }
    }
  }
}
