// Comment Box

.track-modal-comment-box {
  border-bottom: 1px solid #282828;
  width: 100%;
  padding: 16px 8px 16px 16px;
  position: relative;

  &:hover {
    border-left: 1px solid #e0235e;
    padding-left: 15px;
  }

  .comment-user {
    align-items: center;
    display: flex;
    margin-bottom: 8px;

    .profile-image {
      align-items: center;
      background-color: #282828;
      background-position: center;
      background-size: cover;
      color: #b3b3b3;
      border-radius: 100%;
      display: flex;
      font-size: 10px;
      font-weight: 600;
      height: 24px;
      justify-content: center;
      line-height: 24px;
      margin-right: 8px;
      text-transform: uppercase;
      width: 24px;
    }

    .user-name {
      font-weight: 500;
      line-height: 24px;
    }
  }

  .comment-body {
    flex: 1;

    .comment-info {
      margin-bottom: 16px;

      .comment-timestamp {
        color: #e0235e;
        cursor: pointer;
        font-size: 14px;
        font-weight: bold;
        margin-right: 4px;

        &:hover {
          color: #fff;
          transition: 0.24s linear;
        }
      }
    }

    .buttons-row {
      display: flex;
      height: 16px;
      justify-content: space-between;
      padding-right: 8px;
    }

    .reply-button {
      color: #b3b3b3;
      cursor: pointer;
      display: inline-block;
      font-size: 12px;
      font-weight: 500;

      &:hover {
        color: #fff;
      }
    }

    .ellipsis-button {
      color: #b3b3b3;
      cursor: pointer;
      display: inline-block;
      font-size: 18px;
      font-weight: 500;

      &:hover {
        color: #fff;
      }
    }
  }

  .reply-form-container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-top: 16px;
  }

  .reply-form {
    background-color: #000;
    border: 1px solid #282828;
    border-radius: 4px;
    color: #fff;
    height: 40px;
    margin-bottom: 8px;
    padding: 0px 16px;
    width: 100%;
  }

  .add-comment-button {
    background-color: #e0235e;
    border-radius: 4px;
    color: #fff;
    cursor: pointer;
    font-weight: 500;
    padding: 6px 10px;
  }

  .add-comment-button-disabled {
    background-color: #282828;
    border-radius: 4px;
    color: #b3b3b3;
    cursor: pointer;
    font-weight: 500;
    padding: 6px 10px;
  }
}
