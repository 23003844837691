.page-container {
  display: inline-block;
  width: 100%;
}

.mainPage {
  background-size: cover;
  box-sizing: border-box;
  display: flex;
  height: 100vh;
  position: absolute;
  width: 100%;
  left: 0px;

  .submission-page-container {
    background: #121212;
    border: 1px solid #282828;
    border-radius: 4px;
    display: inline-block;
    height: 600px;
    margin: 40px auto 160px auto;
    max-width: 660px;
    text-align: left;
    width: 480px;
  }

  .submissionAuthScreen {
    background: #121212;
    border: 1px solid #282828;
    padding: 24px 24px 32px 24px;
    position: relative;

    .description {
      color: white;
      font-size: 24px;
      font-weight: 500;
      margin-left: -400px;
      position: absolute;
      text-align: right;
      width: 240px;
      z-index: 100;
    }

    .logo {
      bottom: 0;
      font-family: "New-York-To-Las-Vegas";
      font-size: 32px;
      margin-top: 80px;
      text-align: center;
    }

    .link-button {
      text-decoration: underline;
    }

    .submission-label {
      color: #b3b3b3;
      font-size: 12px;
      font-weight: 600;
      margin-bottom: 16px;
      text-transform: uppercase;
    }

    .submission-title {
      font-size: 24px;
      font-weight: 600;
      text-align: left;
      margin-bottom: 40px;
      width: 100%;
    }
  }

  .background {
    height: 100vh;
    width: 100vw;
    background-size: cover;
    background-repeat: no-repeat;

    background: 625% 500%/90% 90%
        radial-gradient(
          closest-side,
          rgba(228, 79, 79, 0.7),
          rgba(228, 79, 79, 0)
        )
        no-repeat,
      600% 180%/90% 90%
        radial-gradient(
          closest-side,
          rgba(228, 79, 79, 0.7),
          rgba(228, 79, 79, 0)
        )
        no-repeat,
      100% 150%/75% 50%
        radial-gradient(
          closest-side,
          rgba(250, 250, 218, 0.2),
          rgba(250, 250, 218, 0)
        )
        no-repeat,
      -400% -100%/90% 80%
        radial-gradient(
          closest-side,
          rgba(250, 250, 218, 0.3),
          rgba(250, 250, 218, 0)
        )
        no-repeat,
      -100% -250%/85% 80%
        radial-gradient(
          closest-side,
          rgba(94, 225, 249, 0.8),
          rgba(94, 225, 249, 0)
        )
        no-repeat,
      -170% 100%/70% 60% radial-gradient(
          closest-side,
          rgba(94, 225, 249, 0.6),
          rgba(94, 225, 249, 0)
        ) no-repeat,
      50% 50%/100% 100%
        linear-gradient(
          45deg,
          #6e10ce 10%,
          rgba(110, 16, 206, 0) 70%,
          rgba(255, 255, 255, 0) 90%
        )
        no-repeat,
      linear-gradient(144deg, rgba(233, 235, 104, 0), rgba(233, 235, 104, 0.3))
        no-repeat,
      linear-gradient(
          90deg,
          rgba(104, 184, 235, 0.11),
          rgba(15, 216, 223, 0.11)
        )
        no-repeat,
      #fff;

    background-position: center;
    position: absolute;

    -webkit-animation: filter-animation 6s ease-in-out infinite;
    animation: filter-animation 6s ease-in-out infinite;
    -webkit-animation-direction: alternate;
    animation-direction: alternate;
    position: absolute;
  }

  .submission-background {
    height: 100vh;
    width: 100vw;
    background: #000;
    position: absolute;
  }

  .mainContent {
    background-color: #050406;
    border-radius: 4px;
    height: 600px;
    margin: auto auto;
    width: 300px;
    z-index: 10;

    .title {
      font-size: 18px;
      font-weight: bold;
    }

    .title-large {
      font-size: 24px;
    }
  }
}

@media only screen and (max-width: 600px) {
  .mainPage {
    .mainContent {
      height: 100vh;
      width: 100%;
    }
  }

  .page-container {
    display: inline-block;
    margin-bottom: 120px;
    width: 100%;
  }
}

@media only screen and (max-width: 1000px) {
  .sidebar-mobile-container {
    display: none;
  }
}

@-webkit-keyframes filter-animation {
  0% {
    -webkit-filter: hue-rotate(0deg);
  }

  50% {
    -webkit-filter: hue-rotate(30deg);
  }

  100% {
    -webkit-filter: hue-rotate(0deg);
  }
}

@keyframes filter-animation {
  0% {
    filter: hue-rotate(0deg);
  }

  50% {
    filter: hue-rotate(30deg);
  }

  100% {
    filter: hue-rotate(0deg);
  }
}
