.likes-page {
  .loader-icon {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-top: 40px;
    margin-right: 232px;
    overflow: hidden;
    padding: 16px;
    position: relative;
    animation: fadeInAnimation ease 0.2s;
    animation-iteration-count: 1;
  }

  .page-title {
    font-size: 64px;
    font-weight: 600;
  }
}

@media only screen and (max-width: 660px) {
  .likes-page {
    .page-title {
      font-size: 32px;
    }

    .loader-icon {
      margin-right: 0px;
    }
  }
}
