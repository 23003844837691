.loginScreen {
  height: 100%;
  padding: 24px;
  position: relative;

  .description {
    color: white;
    font-size: 26px;
    font-weight: 500;
    margin-left: -340px;
    position: absolute;
    text-align: right;
    width: 280px;
    z-index: 100;
  }

  .title {
    margin-bottom: 40px;
  }

  .logo {
    bottom: 0;
    font-family: "New-York-To-Las-Vegas";
    font-size: 32px;
    margin-bottom: 40px;
    position: absolute;
    text-align: center;
    width: calc(100% - 48px);
  }

  .link-button {
    text-decoration: underline;
    cursor: pointer;

    &:hover {
      opacity: 0.7;
    }
  }
}

@media only screen and (max-width: 1000px) {
  .loginScreen {
    .description {
      display: none;
    }
  }
}
