.shareTrackModal {
  background-color: #000;
  border-radius: 4px;

  .content-top {
    display: flex;
    justify-content: space-between;
    padding: 24px 24px 0px 24px;
  }

  .content-middle {
    display: none;
  }

  .content-bottom {
    border-top: 1px solid rgb(32, 34, 37);
    padding: 24px 24px 16px 24px;
  }

  .title {
    font-size: 18px;
    font-weight: bold;
  }

  .member-list {
    margin-top: 40px;
    max-height: 240px;
    overflow-y: auto;
    padding: 0px 24px;

    .member-item {
      align-items: center;
      display: flex;
      justify-content: space-between;
      margin-bottom: 24px;
    }

    .member-info {
      align-items: center;
      display: flex;
    }

    .member-icon {
      align-items: center;
      background-color: #36393f;
      border-radius: 100%;
      display: flex;
      font-weight: 500;
      height: 32px;
      justify-content: center;
      width: 32px;
    }

    .member-icon-inactive {
      align-items: center;
      border: 1px dotted #36393f;
      border-radius: 100%;
      color: #b3b3b3;
      display: flex;
      font-weight: 500;
      height: 32px;
      justify-content: center;
      width: 32px;
    }

    .member-name-inactive {
      color: #b3b3b3;
      font-weight: 500;
      margin-left: 16px;
    }

    .member-name {
      font-weight: 500;
      margin-left: 16px;
    }

    .member-permissions {
      position: relative;

      .permission-container {
        align-items: center;
        cursor: pointer;
        display: flex;

        &:hover {
          color: #fff;
        }
      }

      .member-permission-text {
        font-weight: 500;
        margin-right: 4px;
      }
    }
  }

  .divider {
    background-color: #282828;
    height: 1px;
    margin: 24px 0px;
    width: 100%;
  }

  .share-form {
    margin-bottom: 24px;

    label {
      color: #b3b3b3;
      font-size: 12px;
      font-weight: 600;
      text-transform: uppercase;
    }

    .form-box {
      align-items: center;
      display: flex;
      margin-top: 8px;

      .email-form {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        background-color: #282828;
        border-radius: 4px;
      }
    }

    .email-form-container {
      padding: 0px 24px;
    }

    .just-link {
      padding-left: 20px;
      padding-right: 20px;
    }

    .permissions-field {
      border-radius: 0px 4px 4px 0px;
      cursor: pointer;

      display: flex;
      margin-left: -4px;
      padding-right: 16px;
      position: relative;

      &:hover {
        color: #fff;
      }

      .permissions-container {
        align-items: center;
        display: flex;
      }

      .permissions-text {
        margin-right: 4px;
      }
    }

    .link-access-text {
      margin-left: 16px;
    }

    .sharing-link-field {
      background-color: #000;
      border: 1px solid #36393f;
      color: #b3b3b3;
    }

    .text-field {
      flex: 1;
    }

    .invite-button {
      margin-left: 8px;
      width: 120px;
    }
  }
}
