.folder-page {
  .page-top {
    padding-top: 96px;
    padding-bottom: 32px;
  }

  .page-label {
    align-items: center;
    color: #b3b3b3;
    display: flex;
    font-size: 12px;
    font-weight: 600;
    height: 24px;
    padding-left: 3px;
    text-transform: uppercase;
  }

  .folder-label {
    align-items: center;
    color: #fff;
    display: flex;
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 16px;
  }

  .loader-icon {
    align-items: center;
    display: flex;
    height: 400px;
    justify-content: center;
    width: 100%;
  }

  .folder-info-container {
    display: flex;
    justify-content: space-between;
    width: 100%;

    .folder-page-info {
      display: flex;

      .folder-details {
        flex: 1;
        padding-right: 40px;
      }
    }

    .folder-image {
      background-color: #282828;
      background-position: center;
      background-size: cover;
      border-radius: 2px;
      box-shadow: 0 4px 60px rgba(0, 0, 0, 0.7);
      height: 214px;
      margin-right: 32px;
      width: 214px;
    }

    .folder-page-name {
      font-size: 80px;
      font-weight: 600;
    }

    .folder-owner {
      color: #b3b3b3;
      font-size: 16px;
      font-weight: 500;
    }

    .folder-description {
      color: #b3b3b3;
      margin-top: 40px;
    }

    .folder-buttons {
      display: flex;
      margin-top: 16px;
      position: relative;

      .add-to-library-button {
        margin-right: 8px;
      }

      .add-tracks-button {
        margin-right: 8px;
      }

      .create-folder-button {
        margin-right: 8px;
      }

      .share-button {
        margin-right: 16px;
      }
    }

    .folder-buttons-mobile {
      display: none;
    }

    .folder-menu-button {
      align-items: center;
      color: rgba(255, 255, 255, 0.7);
      cursor: pointer;
      display: flex;
      height: 40px;
      font-size: 22px;

      &:hover {
        color: #fff;
        transition: 0.2s linear;
        transform: scale(1.06);
      }
    }
  }

  .sharing-link-field-mobile {
    height: 100px;
    width: 100px;
    background: #000;
    opacity: 0;
    position: absolute;
  }

  .share-button-mobile {
    display: none;
  }

  .folders-container {
    display: inline-block;
    width: 100%;
  }

  .removed-message-container {
    align-items: center;
    display: flex;
    justify-content: center;

    .removed-message {
      color: #b3b3b3;
      font-size: 16px;
      margin: 24px 232px 24px 0px;
      text-align: center;
    }
  }
}

@media only screen and (max-width: 660px) {
  .folder-page {
    height: 100vh;
    margin-left: 0px;
    width: 100%;

    .removed-message-container .removed-message {
      margin-right: 0px;
    }

    .filter-container {
      display: none;
    }

    .share-button-mobile {
      display: none;
      width: 100%;
    }

    .folder-info-container {
      .folder-page-info {
        align-items: center;
        display: flex;
        flex-direction: column;
        width: 100%;

        .folder-details {
          align-items: center;
          display: flex;
          flex-direction: column;
          padding-right: 0px;

          .folder-page-name {
            font-size: 24px;
            font-weight: 600;
            text-align: center;
          }

          .folder-buttons-mobile {
            display: flex;
          }
        }
      }

      .folder-image {
        height: 180px;
        margin-bottom: 24px;
        margin-right: 0px;
        width: 180px;
      }

      .folder-buttons {
        display: none;
      }
    }

    .share-button-mobile {
      display: flex;
      margin-top: 16px;
      width: 120px;
    }

    .menu-button {
      display: none;
    }
  }

  .share-button {
    display: none;
  }

  .add-tracks-button {
    display: none;
  }

  .create-folder-button {
    display: none;
  }
}
