.track-modal {
  display: flex;
  height: 100%;
  max-height: 100vh;
  width: 100%;

  // Loading

  .loading-container {
    align-items: center;
    display: flex;
    justify-content: center;
    flex: 1;
  }

  // Track Section

  .track-section {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
    position: relative;

    .track-section-content {
      background: linear-gradient(#121212, #000);
      border-radius: 4px 0px 0px 0px;
      display: flex;
      flex: 1;
      flex-direction: column;
      justify-content: space-between;
      padding: 32px 32px 8px 32px;
    }

    .track-top {
      display: flex;

      animation: fadeInAnimation ease 0.4s;
      animation-iteration-count: 1;
      animation-fill-mode: forwards;

      @keyframes fadeInAnimation {
        0% {
          opacity: 0;
        }
        100% {
          opacity: 1;
        }
      }
      .track-image {
        background-color: #121212;
        background-position: center;
        background-size: cover;
        height: 180px;
        margin-right: 24px;
        width: 180px;
      }

      .track-info {
        .version-button {
          align-items: center;
          background-color: #282828;
          border-radius: 4px;
          cursor: pointer;
          display: flex;
          font-size: 12px;
          font-weight: bold;
          height: 24px;
          justify-content: space-between;
          line-height: 24px;
          margin-bottom: 16px;
          padding: 0px 8px;
          width: 32px;

          ion-icon {
            color: #b3b3b3;
          }
        }

        .track-title {
          font-size: 40px;
          font-weight: 600;
          margin-bottom: 8px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          max-width: 560px;
        }

        .profile-container {
          align-items: center;
          display: flex;
          .profile-image {
            align-items: center;
            background-color: #282828;
            background-position: center;
            background-size: cover;
            color: #b3b3b3;
            border-radius: 100%;
            display: flex;
            font-size: 10px;
            font-weight: 600;
            height: 24px;
            justify-content: center;
            margin-right: 8px;
            text-transform: uppercase;
            width: 24px;
          }
          .user-name {
            font-weight: 500;
            line-height: 24px;
          }
        }
      }
    }

    .track-bottom {
      margin-bottom: 24px;
    }

    .buttons-row {
      align-items: center;
      display: flex;
      margin-top: 32px;

      .play-button {
        align-items: center;
        background-color: #e0235e;
        border-radius: 100%;
        cursor: pointer;
        display: flex;
        height: 56px;
        justify-content: center;
        margin-right: 32px;
        width: 56px;

        ion-icon {
          color: #fff;
          display: flex;
          font-size: 28px;
        }

        &:hover {
          transition: 0.2s linear;
          transform: scale(1.06);
        }
      }

      .like-button {
        color: rgba(255, 255, 255, 0.7);
        cursor: pointer;
        display: flex;
        font-size: 24px;
        margin-right: 32px;

        &:hover {
          color: #e0235e;
          transition: 0.2s linear;
          transform: scale(1.06);
        }
      }

      .like-button-active {
        color: #e0235e;
      }

      .ellipsis-button {
        color: rgba(255, 255, 255, 0.7);
        cursor: pointer;
        display: flex;
        font-size: 24px;
        margin-right: 32px;

        &:hover {
          color: #fff;
          transition: 0.2s linear;
          transform: scale(1.06);
        }
      }
    }
    // Progress Bar

    .progressBar-container {
      margin-bottom: 8px;
      margin-top: 48px;
      width: 100%;
    }

    .seekBar-container {
      flex: 1;
      position: relative;
    }
  }

  // Comments Section

  .comments-section {
    background-color: #121212;
    border-left: 1px solid #282828;
    border-radius: 0px 4px 4px 0px;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 344px;

    .comment-header {
      align-items: center;
      border-bottom: 1px solid #282828;
      display: flex;
      height: 64px;
      justify-content: space-between;
      padding: 16px;
      width: 100%;

      .comment-label {
        color: #b3b3b3;
        font-weight: bold;
        font-size: 12px;
        text-transform: uppercase;
      }

      .close-icon {
        display: flex;
      }
    }
    .comment-container {
      height: 100%;
      overflow-y: scroll;
      width: 100%;

      .comments-empty-state {
        align-items: center;
        color: #b3b3b3;
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: center;
        padding: 0px 40px;
        text-align: center;

        animation: fadeInAnimation ease 0.4s;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;

        @keyframes fadeInAnimation {
          0% {
            opacity: 0;
          }
          100% {
            opacity: 1;
          }
        }

        .comments-empty-title {
          color: #fff;
          font-size: 16px;
          font-weight: 600;
          margin-bottom: 8px;
        }
      }
    }
  }
}
