/** Global Styling **/
:root {
  --primary-color: #e0245e;
  --primary-color-hover: #faca7d;
  --dark-color: #343a40;
  --light-color: #f4f4f4;
  --danger-color: #dc3545;
  --success-color: #28a745;
  --border-color: #e5e5e5;
}

@font-face {
  font-family: "Scto-Grotesk-A";
  src: url("./fonts/Scto Grotesk A Regular.otf");
  font-weight: normal;
}

@font-face {
  font-family: "Scto-Grotesk-A";
  src: url("./fonts/Scto Grotesk A Medium.otf");
  font-weight: 500;
}

@font-face {
  font-family: "Scto-Grotesk-A";
  src: url("./fonts/SctoGroteskA-Bold.otf");
  font-weight: bold;
}

@font-face {
  font-family: "New-York-To-Las-Vegas";
  src: url("./fonts/NYtoLV.otf");
  font-weight: normal;
}

html {
  overflow: hidden;
  height: 100%;
}

body {
  color: #fff;
  background-color: #000;
  margin: 0;
  font-family: "Scto-Grotesk-A", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input {
  font-family: "Scto-Grotesk-A", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
}

textarea {
  font-family: "Scto-Grotesk-A", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
}

* {
  box-sizing: border-box;
  outline: none;

  /* width 
  &::-webkit-scrollbar {
    width: 6px;
  }

  // Track 
  &::-webkit-scrollbar-track {
    background: transparent;
  }

  // Handle
  &::-webkit-scrollbar-thumb {
    background: #282828;
    border-radius: 10px;
  }

  // Handle on hover
  &::-webkit-scrollbar-thumb:hover {
    background: #282828;
  }*/
}

ul {
  list-style: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  color: inherit;
  text-decoration: none;
}

/** Fonts **/

h1,
h2,
h3,
h4 {
  font-weight: 600;
  margin: 0;
}

h2 {
  font-size: 32px;
}

/** Margins **/

.bottom-16 {
  margin-bottom: 16px;
}

.bottom-24 {
  margin-bottom: 24px;
}

.bottom-40 {
  margin-bottom: 40px;
}

.bottom-48 {
  margin-bottom: 48px;
}

.bottom-64 {
  margin-bottom: 64px !important;
}

.bottom-80 {
  margin-bottom: 80px;
}

.right-8 {
  margin-right: 8px;
}

.right-40 {
  margin-right: 40px;
}

.top-16 {
  margin-top: 16px;
}

.justify-center {
  justify-content: center;
}

.left-auto {
  margin-left: auto;
}

.left-24 {
  margin-left: 24px;
}

/** Layout **/

.container {
  margin: 0 auto;
  width: 1040px;
}

.container-large {
  margin: 0 auto;
  width: 1180px;
}

.flex {
  display: flex;
}

.float-right {
  float: right;
}

.float-left {
  float: left;
}

.full-width {
  width: 100%;
}

/** Toast **/

.Toastify__toast {
  border-radius: 4px !important;
}

.Toastify__toast--success {
  background-color: var(--success-color) !important;
}

.Toastify__toast--error {
  background-color: var(--danger-color) !important;
}

/** Button **/

.button {
  align-items: center;
  background: var(--primary-color);
  border: none;
  border-radius: 40px;
  color: white;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-weight: 600;
  height: 40px;
  padding: 0px 24px;
  justify-content: center;
  text-decoration: none;
  -webkit-appearance: none;

  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */

  &:hover {
    background-color: #fff;
    color: #282828;
    transition: 0.24s linear;
  }
}

.button-dark {
  align-items: center;
  background: #36393f;
  border: none;
  border-radius: 4px;
  color: white;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-weight: 500;
  height: 40px;
  padding: 0px 24px;
  justify-content: center;
  text-decoration: none;
  -webkit-appearance: none;

  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */

  &:hover {
    opacity: 0.8;
  }
}

.button-secondary {
  align-items: center;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 40px;
  color: #fff;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-weight: 600;
  height: 40px;
  padding: 0px 24px;
  justify-content: center;
  text-decoration: none;
  -webkit-appearance: none;

  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */

  &:hover {
    border: 1px solid #fff;
    color: #fff;
    transition: 0.24s linear;
    transform: scale(1.03);
  }
}

.button--inactive {
  align-items: center;
  background: #999;
  border: none;
  border-radius: 4px;
  color: #f3f3f3;
  cursor: not-allowed;
  display: flex;
  font-size: 16px;
  font-weight: bold;
  height: 48px;
  padding: 0px 24px;
  justify-content: center;
  -webkit-appearance: none;
}

.small-button {
  align-items: center;
  background: var(--primary-color);
  border: none;
  border-radius: 4px;
  color: white;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
  height: 32px;
  line-height: 32px;
  padding: 0px 16px;
  justify-content: center;
  text-decoration: none;
  -webkit-appearance: none;

  &:hover {
    opacity: 0.8;
  }
}

.small-button-secondary {
  align-items: center;
  border: 1px solid var(--primary-color);
  border-radius: 4px;
  color: var(--primary-color);
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
  height: 32px;
  line-height: 32px;
  padding: 0px 16px;
  justify-content: center;
  text-decoration: none;
  -webkit-appearance: none;

  &:hover {
    opacity: 0.8;
  }
}

/** Input Field **/

.text-field {
  align-items: center;
  background-color: #181818;
  border: none;
  border-radius: 4px;
  color: #fff;
  display: flex;
  font-size: 16px;
  height: 40px;
  line-height: 48px;
  padding: 0px 16px;
  width: 100%;
  resize: none;

  &::placeholder {
    color: #b3b3b3;
  }
}

.text-field-small {
  align-items: center;
  background-color: #181818;
  border: 1px solid #383838;
  border-radius: 4px;
  color: #fff;
  display: flex;
  font-size: 14px;
  height: 38px;
  line-height: 48px;
  padding: 0px 16px;
  width: 100%;
  resize: none;

  &::placeholder {
    color: #666;
  }
}

.text-area {
  background-color: #181818;
  border: none;
  border-radius: 4px;
  color: #b3b3b3;
  font-size: 16px;
  height: 96px;
  padding: 8px 16px;
  width: 100%;
  resize: none;

  &::placeholder {
    color: #b3b3b3;
  }
}

/** Slider Switch **/

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: var(--primary-color);
}

input:focus + .slider {
  box-shadow: 0 0 1px var(--primary-color);
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

/*** Page Sizing ***/

.main-page-container {
  background: linear-gradient(45deg, #0c0c0c, black);
  height: calc(100vh - 90px);
  margin-left: 232px;
  overflow-y: scroll;
  position: relative;
  width: calc(100% - 232px);
}

.page-top {
  padding: 96px 40px 16px 40px;
}

.page-content {
  display: inline-block;
  width: 100%;

  padding: 32px 40px 40px 40px;
}

@media only screen and (max-width: 1000px) {
  .page-size {
    margin-left: 0px;
    width: 100%;
  }

  .page-top {
    padding: 64px 16px 32px 16px;
  }

  .page-content {
    margin-bottom: 80px;
    padding: 0px 16px 64px 16px;
  }
  .main-page-container {
    height: calc(100vh - 100px);
    margin-left: 0px;
    width: 100%;
  }

  input {
    font-size: 16px;
  }

  textarea {
    font-size: 16px;
  }
}

/** Spin Animation **/

@-moz-keyframes spin {
  from {
    -moz-transform: rotate(0deg);
  }
  to {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
