.notification-item {
  display: flex;
  padding: 16px;

  .user-image {
    align-items: center;
    background-color: #282828;
    background-position: center;
    background-size: cover;
    color: #b3b3b3;
    border-radius: 100%;
    display: flex;
    font-size: 12px;
    font-weight: 600;
    height: 36px;
    justify-content: center;
    margin-right: 16px;
    text-transform: uppercase;
    width: 36px;
  }
  .notification-info {
    flex: 1;

    .notification-message {
      margin-bottom: 4px;
      .user-name {
        font-weight: 600;
      }
      .track-name {
        color: var(--primary-color);
        font-weight: 500;
        margin-left: 4px;

        &:hover {
          color: #fff;
          transition-duration: 0.24s;
        }
      }
    }

    .notification-time {
      color: #b3b3b3;
      font-size: 12px;
    }
  }
}
