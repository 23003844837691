.upload-modal-edit-track {
  background-color: #000;
  border: 1px solid #282828;
  border-radius: 4px;
  display: inline-block;
  width: 100%;

  .tabs {
    display: flex;
    height: 32px;
    margin-top: 16px;
    padding: 0px 16px;
    width: 100%;

    .tab {
      cursor: pointer;
      font-size: 14px;
      font-weight: 600;
      margin-right: 40px;
      text-transform: uppercase;

      &:hover {
        color: var(--primary-color);
      }
    }
    .tab-active {
      color: var(--primary-color);
      border-bottom: 1px solid var(--primary-color);
    }
  }

  .form-content {
    border-top: 1px solid #282828;
    display: flex;

    margin-top: -1px;
    padding: 16px 16px;
    width: 100%;

    .name-input {
      margin-right: 8px;
      width: 220px;
    }

    .type-input {
      margin-right: 8px;
      width: 120px;
    }

    .producer-input {
      flex: 1;
    }

    .bpm-input {
      width: 80px;
      margin-right: 8px;
    }

    .notes-input {
      flex: 1;
    }

    .sharing-input {
      flex: 1;
    }

    .input-label {
      color: #b3b3b3;
      font-size: 12px;
      font-weight: 600;
      margin-bottom: 4px;
      text-transform: uppercase;
    }
  }

  .bottom-section {
    align-items: center;
    border-top: 1px solid #282828;
    display: flex;

    justify-content: space-between;
    padding: 16px 16px;
    width: 100%;

    .delete-button {
      cursor: pointer;
      text-decoration: underline;

      &:hover {
        color: #fff;
      }
    }

    .bottom-left {
    }

    .bottom-right {
      align-items: center;
      display: flex;

      .private-upload {
        align-items: center;

        display: flex;
        margin-right: 40px;

        .icon {
          align-items: center;
          color: #b3b3b3;
          cursor: pointer;
          display: flex;
          font-size: 22px;
          margin-right: 8px;

          &:hover {
            color: #fff;
          }
        }

        .text {
          line-height: 22px;
        }
      }
    }
  }
}
