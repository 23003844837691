.mobile-nav-menu {
  height: 100%;
  position: absolute;
  z-index: 1000;

  .close-button {
    align-items: center;
    background-color: #000;
    border-top: 1px solid #181818;
    bottom: 0px;
    cursor: pointer;
    display: flex;
    font-size: 16px;
    font-weight: 500;
    height: 60px;
    justify-content: center;
    left: 0;
    position: absolute;
    width: 232px;
  }
}
