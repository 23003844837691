.upload-modal-dropzone {
  display: inline-block;
  height: 100%;
  width: 100%;

  .padding-container {
    padding: 0px 16px;
  }

  .select-type-container {
    align-items: center;
    display: flex;
    margin-bottom: 24px;

    .type-label {
      color: #b3b3b3;
      font-size: 12px;
      font-weight: 600;
      line-height: 15px;
      margin-right: 16px;
      text-transform: uppercase;
    }

    .type-button {
      align-items: center;
      background-color: #1e1e1e;
      border-radius: 4px;
      cursor: pointer;
      display: flex;
      font-weight: 500;
      height: 32px;
      justify-content: center;
      margin: 0 4px;
      padding: 0px 16px;

      &:hover {
        background-color: #fff;
        color: #000;
        transition: 0.24s linear;
      }
    }

    .type-button-active {
      background-color: #fff;
      color: #000;
    }
  }

  .dropzone {
    align-items: center;
    background-color: #070707;
    border: 1px dashed #282828;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 32px;
    padding: 16px 40px;
    width: 100%;

    &:hover {
      border-color: #b3b3b3;
    }

    .add-button {
      align-items: center;
      background-color: var(--primary-color);
      border-radius: 100%;
      color: #fff;
      display: flex;
      font-size: 26px;
      height: 40px;
      justify-content: center;
      margin-bottom: 24px;
      width: 40px;
    }

    .dropzone-title {
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 16px;
    }

    .dropzone-sub-title {
      color: #b3b3b3;
    }
  }

  .or-divider {
    align-items: center;
    display: flex;
    justify-content: center;
    margin-bottom: 24px;

    .divider {
      background-color: #515459;
      border-radius: 4px;
      height: 1px;
      width: 80px;
    }
    .or-text {
      color: #b3b3b3;
      font-size: 14px;
      font-weight: 600;
      margin: 0px 8px;
      text-transform: uppercase;
    }
  }

  .add-catalog-button {
    width: 180px;
    margin: 0 auto 24px auto;
  }

  .attachment-label {
    color: #b3b3b3;
    font-size: 12px;
    font-weight: 600;
    margin-bottom: 8px;
    padding-left: 16px;
    text-transform: uppercase;
  }

  .track-container {
    height: calc(100vh - 364px);
    overflow-y: scroll;
    padding: 8px 8px 80px 16px;

    .empty-state-container {
      background-color: #282828;
      border-radius: 4px;
      color: #8f8e94;
      display: inline-block;
      font-size: 14px;
      margin-top: 16px;
      padding: 16px;
    }
  }

  .upload-bottom-bar {
    align-items: center;
    background-color: #000;
    border-top: 1px solid #282828;
    bottom: 0;
    display: flex;
    height: 90px;
    justify-content: center;
    left: 0;
    padding: 0px 40px;
    position: absolute;
    width: 100%;
    z-index: 10;

    .upload-button {
      padding: 0px 64px;
    }
  }
}
