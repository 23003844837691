.addFromCatalogModal {
  background-color: #000;
  border-radius: 4px;

  .content-top {
    align-items: center;
    border-bottom: 1px solid #282828;

    display: flex;
    justify-content: space-between;
    padding: 16px;

    .title {
      font-size: 18px;
      font-weight: bold;
    }
  }

  .close-icon {
    display: flex;
  }

  .content-body {
    margin-top: -1px;
    min-height: 300px;
    max-height: 400px;
    overflow-y: scroll;
    width: 100%;

    .add-track {
      align-items: center;
      border-top: 1px solid #282828;
      cursor: pointer;
      display: flex;
      height: 64px;
      padding: 0px 16px;

      &:hover {
        background-color: #282828;
      }

      .add-icon {
        display: flex;
        font-size: 22px;
      }

      .info {
        margin-left: 24px;

        .track-name {
          font-weight: 500;
          margin-bottom: 2px;
        }
        .track-tags {
          color: #b3b3b3;
        }
      }
    }
  }

  .content-bottom {
    align-items: center;
    border-top: 1px solid #282828;
    display: flex;
    justify-content: space-between;
    padding: 16px 16px;

    .selected-count {
      font-weight: 500;
    }
  }
}
