.authModal {
  .content-top {
    display: flex;
    justify-content: space-between;
  }

  .title {
    font-size: 18px;
    font-weight: bold;
  }

  .text-field {
    margin-bottom: 8px;
  }

  .link-text {
    display: flex;
    margin-bottom: 24px;

    .link {
      color: var(--primary-color);
      cursor: pointer;
      margin-left: 8px;
      text-decoration: underline;

      &:hover {
        opacity: 0.6;
      }
    }
  }
}
