.accountSettingsModal {
  .content-top {
    align-items: center;
    border-bottom: 1px solid #282828;
    display: flex;
    justify-content: space-between;
    padding: 16px;
  }

  .close-icon {
    display: flex;
  }

  .title {
    font-size: 18px;
    font-weight: bold;
  }

  .content-middle {
    display: flex;
    height: 400px;

    .settings-menu {
      border-right: 1px solid #282828;
      height: 100%;
      width: 200px;

      .menu-item {
        align-items: center;
        color: #b3b3b3;
        cursor: pointer;
        display: flex;
        font-size: 16px;
        font-weight: 500;
        height: 48px;
        padding: 0px 16px;
        width: 100%;

        &:hover {
          border-left: 2px solid var(--primary-color);
          color: #fff;
          padding: 0px 16px 0px 14px;
        }
      }

      .menu-item-active {
        border-left: 2px solid var(--primary-color);
        color: #fff;
        padding: 0px 16px 0px 14px;
      }
    }

    .settings-body {
      flex: 1;
      padding: 24px 40px;

      .input-container {
        margin-bottom: 24px;
        .input-label {
          color: #b3b3b3;
          font-size: 12px;
          font-weight: 600;
          margin-bottom: 8px;
          text-transform: uppercase;
        }
      }

      .save-button {
        width: 160px;
      }

      .disabled-button {
        pointer-events: none;
        opacity: 0.5;
      }
    }
  }
}
